import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import CustomersContext from "../../context/otogas/customers/customersContext";

function GlobalPriceChangeModal({ show, handleClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { listProducts, products, setGlobalPricing } =
    useContext(CustomersContext);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    // Fetch products when the component mounts

    listProducts(); // Ensure listProducts fetches and sets the products
  }, []);

  useEffect(() => {
    if (products) {
      // Transform products into options for react-select
      setProductOptions(
        products.map((product) => ({
          value: product.productCode,
          label: product.productName,
        })),
      );
    }
  }, [products]);

  useEffect(() => {
    if (selectedProduct) {
      setValue("productCode", selectedProduct.value); // Update the form value when product is selected
    }
  }, [selectedProduct, setValue]);

  const handleFormSubmit = async (data) => {
    console.log("Data:", data);

    setGlobalPricing(data.productCode, data.newPrice);

    handleClose(); // Close the modal after successful submission
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Global Price Change</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formProductCode">
            <Form.Label>Product</Form.Label>
            <Select
              options={productOptions}
              onChange={setSelectedProduct} // Update selectedProduct state on selection
              placeholder="Select a product"
              value={selectedProduct} // Control the selected product
            />
            <input
              type="hidden"
              {...register("productCode", {
                required: "Product code is required",
              })}
            />
            {errors.productCode && (
              <p className="text-danger">{errors.productCode.message}</p>
            )}
          </Form.Group>

          <Form.Group controlId="formNewPrice">
            <Form.Label>New Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter new price"
              {...register("newPrice", {
                required: "New price is required",
                min: {
                  value: 0,
                  message: "Price must be greater than or equal to 0",
                },
              })}
              isInvalid={!!errors.newPrice}
            />
            <Form.Control.Feedback type="invalid">
              {errors.newPrice?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Apply Price Change
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default GlobalPriceChangeModal;
