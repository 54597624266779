import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import CustomersContext from '../../context/otogas/customers/customersContext';

const ChangeVehicleStatusModal = ({ show, handleClose, vehicleCode, status }) => {
  const customerContext = useContext(CustomersContext);
  const { updateVehicleStatus } = customerContext; // Assuming you have this function in the context
  

  


  const handleSave = () => {
    if (vehicleCode) {
      // Call the updateVehicleStatus function from CustomersContext or any other logic
      updateVehicleStatus(vehicleCode, status);
      handleClose();
    }
  };

  return (
    <Modal show={show} handleClose={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title> 
          {status}
          Vehicle </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formVehicleCode">
            <Form.Label>Vehicle Code</Form.Label>
            <Form.Control type="text" value={vehicleCode} disabled />
          </Form.Group>
          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeVehicleStatusModal;
