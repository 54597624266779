import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import Pagination from "../../components/Layout/Pagination";
import AuthContext from "../../context/auth/authContext";
import StationsContext from "../../context/otogas/stations/stationsContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ListTotalizerReadings = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const stationsContext = useContext(StationsContext);
  const { totalizer_readings, getTotalizerReadings, notification, clear_notification } = stationsContext;


  const [currentPage, setCurrentPage] = useState(1); // Local page number state
  const [itemsPerPage] = useState(10); // Items per page (based on API structure)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
  const [selectedDate, setSelectedDate] = useState(new Date()); // Set today's date as the default
  const [showDatePicker, setShowDatePicker] = useState(false); // To toggle date picker visibility

  // Handle notifications
  useEffect(() => {
    if (notification) {
      setAlertMessage({
        text: notification.text,
        type: notification.type,
      });
      setShowAlert(true);
    }
  }, [notification]);

  useEffect(() => {
    const formattedDate = selectedDate.toISOString().split("T")[0];
    getTotalizerReadings(formattedDate); // Initial load with today's date
  }, [selectedDate]); // Ensure this runs only when selectedDate changes

  const handleConfirm = () => {
    setShowAlert(false);
    clear_notification();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Set the selected date
    setShowDatePicker(false); // Hide the date picker after selection
    if (date) {
      const formattedDate = date.toISOString().split("T")[0];
      getTotalizerReadings(formattedDate); // Fetch data based on the selected date
    }
  };

  // Check if totalizer_readings is defined and has items
  const totalItems = totalizer_readings.length;
  const totalPages = Math.ceil((totalItems || 1) / itemsPerPage);

  // Get the current items to display
  const indexOfLastTotalizer = currentPage * itemsPerPage;

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page="List Totalizers" />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type || "info"}
        />

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">List Totalizer Readings</h5>

            {/* Date Picker Button */}
            <div className="row mb-3">
              <div className="col-md-3">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setShowDatePicker(!showDatePicker)}
                >
                  {selectedDate
                    ? `Filter by Date: ${selectedDate.toLocaleDateString()}`
                    : "Select Date"}
                </button>
              </div>
            </div>

            {/* Conditionally show DatePicker when button is clicked */}
            {showDatePicker && (
              <div className="mb-3">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  isClearable
                  inline
                />
              </div>
            )}

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Nozzle Code</th>
                    <th>Nozzle Name</th>
                    <th>Dispenser Name</th>
                    <th>Station Name</th>
                    <th>Totalizer Reading</th>
                  </tr>
                </thead>
                <tbody>
                  {totalizer_readings.length > 0 ? totalizer_readings.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((totalizer, index) => (
                    <tr key={index}>
                      <td>{totalizer.nozzleCode.nozzleCode}</td>
                      <td>{totalizer.nozzleName}</td>
                      <td>{totalizer.dispenserName}</td>
                      <td>{totalizer.stationName}</td>
                      <td>{totalizer.totalizerReading}</td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No totalizer readings available at the moment
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                />
              )}
            </div>

            {/* Pagination */}

          </div>
        </div>
      </Main>
    </div>
  );
};

export default ListTotalizerReadings;
