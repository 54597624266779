import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';
import CustomersContext from "../../context/otogas/customers/customersContext";

const TransferAmountModal = ({ show, handleClose, customerCode }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [amount, setAmount] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(null); // State to store form data
    const customerContext = useContext(CustomersContext);
    const { transferVehicleBalance, getCustomerVehicles, customer_vehicles } = customerContext;

    const [fromVehicle, setFromVehicle] = useState(null); // Store entire vehicle object
    const [toVehicle, setToVehicle] = useState(null); // Store entire vehicle object

    // Fetch customer vehicles when customerCode is provided
    useEffect(() => {
        if (customerCode) {
            getCustomerVehicles(customerCode);
        }
    }, [customerCode]);

    const onSubmit = (data) => {
        // Store form data in state and show the SweetAlert confirmation
        setFormData(data); // Store the form data to use later
        setShowAlert(true);
    };

    const handleConfirm = () => {
        // Proceed with form submission logic after confirmation
        console.log(`Transferring ${amount} from ${fromVehicle?.vehicleRegistrationNumber} to ${toVehicle?.vehicleRegistrationNumber}`);
        setShowAlert(false);
        // Use the stored formData to proceed with transfer
        transferVehicleBalance({
            ...formData, // Use the stored form data
            fromVehicleCode: fromVehicle?.vehicleCode,
            toVehicleCode: toVehicle?.vehicleCode
        });
        handleClose(); // Close the modal after submission
    };

    const handleCancel = () => {
        // Hide the SweetAlert without submitting the form
        setShowAlert(false);
    };

    // Map vehicles to options for react-select with vehicle registration as the label
    const vehicleOptions = customer_vehicles.vehicles.map(vehicle => ({
        value: vehicle,
        label: `${vehicle.vehicleRegistrationNumber} - Balance ${vehicle.balance}`,
    }));

    // Filter the 'To Vehicle' options to exclude the selected 'From Vehicle'
    const filteredToVehicleOptions = vehicleOptions.filter(
        (option) => option.value.vehicleCode !== fromVehicle?.vehicleCode
    );

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer Amount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* From Vehicle Dropdown */}
                        <Form.Group controlId="fromVehicleCode">
                            <Form.Label>From Vehicle</Form.Label>
                            <Select
                                options={vehicleOptions}
                                value={vehicleOptions.find(option => option.value.vehicleCode === fromVehicle?.vehicleCode)}
                                onChange={(selectedOption) => setFromVehicle(selectedOption.value)} // Store entire vehicle object
                                placeholder="Select From Vehicle"
                            />
                            {errors.fromVehicleCode && <span className="text-danger">This field is required</span>}
                        </Form.Group>

                        {/* To Vehicle Dropdown */}
                        <Form.Group controlId="toVehicleCode">
                            <Form.Label>To Vehicle</Form.Label>
                            <Select
                                options={filteredToVehicleOptions} // Filtered options for 'To Vehicle'
                                value={filteredToVehicleOptions.find(option => option.value.vehicleCode === toVehicle?.vehicleCode)}
                                onChange={(selectedOption) => setToVehicle(selectedOption.value)} // Store entire vehicle object
                                placeholder="Select To Vehicle"
                            />
                            {errors.toVehicleCode && <span className="text-danger">This field is required</span>}
                        </Form.Group>

                        {/* Amount Input */}
                        <Form.Group controlId="amount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Amount"
                                {...register('amount', {
                                    required: true,
                                    min: 1,
                                    validate: value =>
                                        fromVehicle && value <= fromVehicle.balance || 'Amount exceeds available balance'
                                })}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            {errors.amount && <span className="text-danger">{errors.amount.message}</span>}
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* SweetAlert Confirmation */}
            <SweetAlertWrapper
                show={showAlert}
                title="Confirm Action"
                message={`Are you sure you want to transfer ${amount} from vehicle ${fromVehicle?.vehicleRegistrationNumber} to vehicle ${toVehicle?.vehicleRegistrationNumber}?`}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                confirmBtnText="Yes, transfer"
                cancelBtnText="No"
                showCancelButton={true}
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="secondary"
                type="warning"
            />
        </>
    );
};

export default TransferAmountModal;
