import React, { useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomerContext from "../../context/otogas/customers/customersContext";
import Select from "react-select";

const VehicleTopupModal = ({ show, handleClose, vehicle }) => {
  const customerContext = useContext(CustomerContext);
  const { topUpCustomerBalance, listTopupPaymentTypes, topup_payment_types } = customerContext;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      vehicleCode: vehicle.vehicleRegistrationNumber, // Pre-fill the vehicle code with vehicleRegistrationNumber
      paymentType: null,
      amount: 0,
    },
  });

  useEffect(() => {
    listTopupPaymentTypes();
  }, []);



  const onSubmit = (data) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to top up the vehicle with registration number ${vehicle.vehicleRegistrationNumber}?`
    );

    if (isConfirmed) {
      topUpCustomerBalance({
        ...data,
        vehicleCode: vehicle.vehicleCode,
      });
      handleClose();
    } else {
      alert("Vehicle top-up cancelled.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Top-up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Vehicle Code (Non-editable) */}
          <Form.Group controlId="vehicleCode">
            <Form.Label>Vehicle</Form.Label>
            <Form.Control
              type="text"
              value={vehicle.vehicleRegistrationNumber}
              readOnly
              {...register("vehicleCode")}
            />
          </Form.Group>

          {/* Transaction Reference */}
          <Form.Group controlId="transactionReference" className="mt-3">
            <Form.Label>Transaction Reference</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter transaction reference"
              {...register("transactionReference", {
                required: "Transaction reference is required",
              })}
              isInvalid={errors.transactionReference}
            />
            {errors.transactionReference && (
              <Form.Control.Feedback type="invalid">
                {errors.transactionReference.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Payment Type */}
          <Form.Group controlId="paymentType" className="mt-3">
            <Form.Label>Select Payment Type</Form.Label>
            <Select
              options={topup_payment_types.map((type) => ({
                value: type.topUpTypeId,
                label: type.topUpTypeName.replace(/_/g, " "),
              }))}
              onChange={(selectedOption) => {
                setValue("paymentType", selectedOption.value); // Update form value
              }}
              placeholder="Select Payment Type"
            />
            {errors.paymentType && (
              <Form.Control.Feedback type="invalid">
                {errors.paymentType.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Amount */}
          <Form.Group controlId="amount" className="mt-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              {...register("amount", { required: "Amount is required", min: 1 })}
              isInvalid={errors.amount}
            />
            {errors.amount && (
              <Form.Control.Feedback type="invalid">
                {errors.amount.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Submit Button */}
          <Button variant="primary" type="submit" className="mt-4">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VehicleTopupModal;
