import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Modal, Button, Form } from "react-bootstrap";
import StationsContext from "../../context/otogas/stations/stationsContext";
import CustomersContext from "../../context/otogas/customers/customersContext";

const TransferSale = ({ show, handleClose, sale }) => {
  const [nozzleCode, setNozzleCode] = useState(null);

  const stationsContext = useContext(StationsContext);
  const { nozzles, listNozzles } = stationsContext;

  const customersContext = useContext(CustomersContext);
  const { transferSale } = customersContext;

  // Fetch nozzles when the component mounts
  useEffect(() => {
    if (sale) {
      listNozzles(); // Fetch all nozzles
    }
  }, [sale]);

  // Only filter nozzles if sale and sale.dispenserCode exist
  const filteredNozzles = sale
    ? nozzles.filter((nozzle) => nozzle.dispenserCode === sale.dispenserCode)
    : [];

  const handleNozzleChange = (selectedOption) => {
    setNozzleCode(selectedOption ? selectedOption.value : null);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!nozzleCode) {
      alert("Please select a nozzle");
      return;
    }

    // Handle sale transfer
    transferSale(
      sale.saleId,
      nozzleCode,
    );

    handleClose(); // Close the modal after transfer
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Sale</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group controlId="formTransactionCode">
            <Form.Label>Transaction Code</Form.Label>
            <Form.Control
              type="text"
              value={sale ? sale.saleId : ""}
              readOnly
            />
          </Form.Group>

          {/* Nozzle dropdown filtered by sale.dispenserCode */}
          {filteredNozzles.length > 0 ? (
            <Form.Group controlId="formNozzleSelect">
              <Form.Label>Select Nozzle</Form.Label>
              <Select
                value={filteredNozzles.find(
                  (nozzle) => nozzle.value === nozzleCode
                )}
                onChange={handleNozzleChange}
                options={filteredNozzles.map((nozzle) => ({
                  value: nozzle.nozzleCode,
                  label: nozzle.nozzleName,
                }))}
                placeholder="Select Nozzle"
                isClearable
              />
            </Form.Group>
          ) : (
            <p>No nozzles available for the selected dispenser.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Transfer Sale
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TransferSale;
