import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller, } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap'
import CustomersContext from "../../context/otogas/customers/customersContext";
import StationsContext from "../../context/otogas/stations/stationsContext";
import { format } from 'date-fns';
import Select from "react-select";

const UpdateCustomerVehicle = ({ show, handleClose, selected_vehicle }) => {
    const navigate = useNavigate();
    const { UpdateVehicle, products, listProducts, tank_sizes, listTankSizes, customers, getCustomers, notification, clear_notification } = useContext(CustomersContext);
    const { stations, listStations } = useContext(StationsContext);
    const { register, control, handleSubmit, watch, setValue, formState: { errors, isValid } } = useForm({
        mode: "onChange",
        defaultValues: {
            customerCode: selected_vehicle ? selected_vehicle.customerCode : "",
            vehicleRegistrationNumber: selected_vehicle ? selected_vehicle.vehicleRegistrationNumber : "",
            vehicleMake: selected_vehicle ? selected_vehicle.vehicleMake : "",
            vehicleNumber: selected_vehicle ? selected_vehicle.vehicleRegistrationNumber : "",
            vehicleModel: selected_vehicle ? selected_vehicle.vehicleModel : "",
            tankCapacity: selected_vehicle ? selected_vehicle.tankCapacity : "",
            productCode: selected_vehicle ? selected_vehicle.productCode : "",
            conversionStation: selected_vehicle ? selected_vehicle.conversionStation : "",
            conversionDate: selected_vehicle ? format(new Date(selected_vehicle.conversionDate), "yyyy-MM-dd") : "",
            status: selected_vehicle ? selected_vehicle.status : 1,
            vehicleCode: selected_vehicle ? selected_vehicle.vehicleCode : "",
            phoneNumber: selected_vehicle ? selected_vehicle.phoneNumber : "",
            phoneNumber2: selected_vehicle ? selected_vehicle.phoneNumber2 : "",
        }
    });

    useEffect(() => {
        // getCustomers();
        listProducts();
        listTankSizes()
        listStations();
    }, []);

    // Pre-fill form values when a selected vehicle is provided
    useEffect(() => {
        if (selected_vehicle) {
            setValue("customerCode", selected_vehicle.customerCode);
            setValue("vehicleRegistrationNumber", selected_vehicle.vehicleRegistrationNumber);
            setValue("vehicleMake", selected_vehicle.vehicleMake);
            setValue("vehicleNumber", selected_vehicle.vehicleRegistrationNumber);
            setValue("vehicleModel", selected_vehicle.vehicleModel);
            setValue("tankCapacity", selected_vehicle.tankCapacity);
            setValue("productCode", selected_vehicle.productCode);
            setValue("conversionStation", selected_vehicle.conversionStation);
            setValue("conversionDate", format(new Date(selected_vehicle.conversionDate), "yyyy-MM-dd"));
            setValue("isActive", selected_vehicle.isActive);
            setValue("vehicleCode", selected_vehicle.vehicleCode);
            setValue("phoneNumber", selected_vehicle.phoneNumber);
            setValue("phoneNumber2", selected_vehicle.phoneNumber2);
        }
    }, [selected_vehicle, setValue]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const handleConfirm = () => {
        setShowAlert(false);
        navigate('/otogas/vehicles');
        clear_notification();
        handleClose();
    };

    const validateTankCapacity = (value) => {
        if (!value) return "Tank Capacity is required";
        if (isNaN(value) || value <= 0) return "Tank Capacity must be a positive number";
        return true;
    };

    const onSubmit = (data) => {
        console.log(data);
        UpdateVehicle(data); // Update vehicle with new data
        handleClose(); // Close modal after submit
    };

    const productCodeOptions = [
        { value: "", label: "Select Product Code" },
        ...products.map(product => ({
            value: product.productCode,
            label: product.productName
        }))
    ];

    const stationOptios = [
        { value: "", label: "Select Station" },
        ...stations.map(station => ({
            value: station.stationCode,
            label: station.stationName
        }))];

    const tankOptions = tank_sizes.map((tank) => ({
        value: tank.tankCapacity,
        label: tank.tankCapacity,
    }));

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-group">
                        <label>Vehicle Registration Number</label>
                        <input
                            type="text"
                            {...register("vehicleRegistrationNumber", { required: "Vehicle Registration Number is required" })}
                            className={`form-control ${errors.vehicleRegistrationNumber ? "is-invalid" : ""}`}

                        />
                        {errors.vehicleRegistrationNumber && <div className="text-danger mt-1">{errors.vehicleRegistrationNumber.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Vehicle Make</label>
                        <input
                            type="text"
                            {...register("vehicleMake", { required: "Vehicle Make is required" })}
                            className={`form-control ${errors.vehicleMake ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleMake && <div className="text-danger mt-1">{errors.vehicleMake.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Vehicle Model</label>
                        <input
                            type="text"
                            {...register("vehicleModel", { required: "Vehicle Model is required" })}
                            className={`form-control ${errors.vehicleModel ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleModel && <div className="text-danger mt-1">{errors.vehicleModel.message}</div>}
                    </div>


                    {/* <div className="form-group">
                        <label>Tank Capacity</label>
                        <Controller
                            name="tankCapacity"
                            control={control}
                            rules={{ validate: validateTankCapacity }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tankOptions}
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption.value); // Set only the value
                                    }}
                                    className={errors.tankCapacity ? "is-invalid" : ""}
                                    value={tankOptions.find(
                                        (option) => option.value === watch("tankCapacity")
                                    )}
                                    placeholder="Select Tank Capacity"
                                />
                            )}
                        />
                        {errors.tankCapacity && (
                            <div className="text-danger mt-1">{errors.tankCapacity.message}</div>
                        )}
                    </div> */}

                    <div className="form-group">
                        <label>Tank Capacity</label>
                        <Controller
                            name="tankCapacity"
                            control={control}
                            rules={{ validate: validateTankCapacity }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tankOptions}
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption.value); // Set only the value
                                    }}
                                    className={errors.tankCapacity ? "is-invalid" : ""}
                                    value={tankOptions.find(
                                        (option) => option.value === watch("tankCapacity") // Prefill the tank capacity based on current value
                                    )}
                                    placeholder="Select Tank Capacity"
                                />
                            )}
                        />
                        {errors.tankCapacity && (
                            <div className="text-danger mt-1">{errors.tankCapacity.message}</div>
                        )}
                    </div>



                    {/* <div className="form-group">
                        <label>Tank Capacity</label>
                        <input
                            type="number"
                            {...register("tankCapacity", { validate: validateTankCapacity })}
                            className={`form-control ${errors.tankCapacity ? "is-invalid" : ""}`}
                        />
                        {errors.tankCapacity && <div className="text-danger mt-1">{errors.tankCapacity.message}</div>}
                    </div> */}

                    <div className="form-group">
                        <label>Product</label>
                        <Controller
                            name="productCode"
                            control={control}
                            rules={{ required: "Product Code is required" }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={productCodeOptions}
                                    classNamePrefix="react-select"
                                    className={errors.productCode ? "is-invalid" : ""}
                                    placeholder="Select Product Code"
                                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                    value={productCodeOptions.find(option => option.value === field.value)}
                                />
                            )}
                        />
                        {errors.productCode && <div className="text-danger mt-1">{errors.productCode.message}</div>}
                    </div>

                    {/* <div className="form-group">
                        <label>Conversion Station</label>
                        <Controller
                            name="conversionStation"
                            control={control}
                            rules={{ required: "Conversion Station is required" }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={stationOptios}
                                    classNamePrefix="react-select"
                                    className={errors.conversionStation ? "is-invalid" : ""}
                                    placeholder="Select Station"
                                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                    value={stationOptios.find(option => option.value === field.value)}
                                />
                            )}
                        />
                        {errors.conversionStation && <div className="text-danger mt-1">{errors.conversionStation.message}</div>}
                    </div> */}

                    <div className="form-group">
                        <label>Conversion Station</label>
                        <Controller
                            name="conversionStation"
                            control={control}
                            rules={{ required: "Conversion Station is required" }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={stationOptios}
                                    classNamePrefix="react-select"
                                    className={errors.conversionStation ? "is-invalid" : ""}
                                    placeholder="Select Station"
                                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                    value={stationOptios.find(option => option.value === field.value)} // Prefill the station
                                />
                            )}
                        />
                        {errors.conversionStation && <div className="text-danger mt-1">{errors.conversionStation.message}</div>}
                    </div>


                    <div className="form-group">
                        <label>Conversion Date</label>
                        <input
                            type="date"
                            {...register("conversionDate", { required: "Conversion Date is required" })}
                            className={`form-control ${errors.conversionDate ? "is-invalid" : ""}`}
                        />
                        {errors.conversionDate && <div className="text-danger mt-1">{errors.conversionDate.message}</div>}
                    </div>

                    {/* <div className="form-group">
                        <label>isActive</label>

                        <Controller
                            name="isActive"
                            control={control}
                            rules={{ required: "Status is required" }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={
                                        [
                                            { value: true, label: "Active" },
                                            { value: false, label: "Inactive" }
                                        ]
                                    }
                                    classNamePrefix="react-select"
                                    className={errors.isActive ? "is-invalid" : ""}
                                    placeholder="Select status"
                                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                    value={
                                        field.value === "true"
                                            ? { value: true, label: "Active" }
                                            : { value: false, label: "Inactive" }
                                    }


                                />
                            )}
                        />
                        {errors.isActive && <div className="text-danger mt-1">{errors.isActive.message}</div>}
                    </div> */}

                    <div className="form-group mt-4">
                        <Button type="submit" variant="primary" disabled={!isValid}>
                            Update Vehicle
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateCustomerVehicle;
