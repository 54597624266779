import React, {useContext} from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import StationsContext from '../../context/otogas/stations/stationsContext';

const RegisterPDAModal = ({ show, handleClose, dispenserCode }) => {
  const stationsContext = useContext(StationsContext);
  const { registerPda } = stationsContext
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dispenserCode: dispenserCode || '', // Set the initial dispenser code if provided
    },
  });

  const submitHandler = (data) => {
    // Handle form submission, e.g., sending data to the server
    console.log('Form Data:', data);
    registerPda(data);
    handleClose(); // Close the modal after submission
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Register PDA Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Form.Group controlId="deviceName">
            <Form.Label>Device Name</Form.Label>
            <Form.Control
              type="text"
              {...register('deviceName', { required: 'Device name is required' })}
              isInvalid={!!errors.deviceName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.deviceName?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="deviceIMEI">
            <Form.Label>Device IMEI</Form.Label>
            <Form.Control
              type="text"
              {...register('deviceIMEI', {
                required: 'Device IMEI is required',
                minLength: { value: 10, message: 'IMEI must be at least 10 characters long' },
              })}
              isInvalid={!!errors.deviceIMEI}
            />
            <Form.Control.Feedback type="invalid">
              {errors.deviceIMEI?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="deviceSerialNumber">
            <Form.Label>Device Serial Number</Form.Label>
            <Form.Control
              type="text"
              {...register('deviceSerialNumber', { required: 'Serial number is required' })}
              isInvalid={!!errors.deviceSerialNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.deviceSerialNumber?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="deviceModel">
            <Form.Label>Device Model</Form.Label>
            <Form.Control
              type="text"
              {...register('deviceModel', { required: 'Device model is required' })}
              isInvalid={!!errors.deviceModel}
            />
            <Form.Control.Feedback type="invalid">
              {errors.deviceModel?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="dispenserCode">
            <Form.Label>Dispenser Code</Form.Label>
            <Form.Control
              type="text"
              {...register('dispenserCode', { required: 'Dispenser code is required' })}
              isInvalid={!!errors.dispenserCode}
              defaultValue={dispenserCode} // Prepopulate the dispenser code if provided
              readOnly={!!dispenserCode} // Make it readonly if passed as a prop
            />
            <Form.Control.Feedback type="invalid">
              {errors.dispenserCode?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit">
            Register PDA
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterPDAModal;
