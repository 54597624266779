import React, {useContext} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import StationsContext from '../../context/otogas/stations/stationsContext';

const InitialStockTakeModal = ({ show, handleClose, nozzleCode }) => {
  const stationsContext = useContext(StationsContext);
  const { setInitialStock } = stationsContext;
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      reading: 0
    }
  });

  const submitForm = (data) => {
    const stockData = {
      reading: data.reading,
      nozzleCode: nozzleCode // Passing nozzleCode from props
    };
    setInitialStock(stockData);
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Initial Stock Take for Nozzle: {nozzleCode}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(submitForm)}>
          <Form.Group controlId="reading">
            <Form.Label>Reading</Form.Label>
            <Controller
              control={control}
              name="reading"
              render={({ field }) => (
                <Form.Control
                  type="number"
                  placeholder="Enter reading value"
                  {...field}
                />
              )}
            />
          </Form.Group>
          <Button variant="success" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InitialStockTakeModal;
