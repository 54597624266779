import axios from "./index";

export const Login = async (formData) => {
    try {
        const res = await axios.post("/otogas/Authentication/SignInUser", {
            ...formData,
            appCode: "01",
        });
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// current user
export const CurrentUser = async (token) => {
    try {
        const res = await axios.get(
            "/otogas/Authentication/CurrentUserDetails",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// user registration
export const Register = async (formData) => {
    try {
        const res = await axios.post(
            "/otogas/Authentication/RegisterUser",
            formData,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};
// update user'

export const UpdateUser = async (userCode, formData) => {
    try {
        const res = await axios.post(
            `/otogas/Authentication/UpdateUserDetails?userCode=${userCode}`,
            formData,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// reset password
export const ResetPassword = async (password) => {
    try {
        const res = await axios.post(
            `/otogas/Authentication/ResetPassword?passWord=${password}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// get users
export const GetUsers = async () => {
    try {
        const res = await axios.get("/otogas/Authentication/GetAllUsers");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const GetUser = async (userCode) => {
    try {
        const res = await axios.get(
            `/otogas/Authentication/CurrentUserDetails?userCode=${userCode}`,
        );
        return res.data.responseObject;
    } catch (error) {
        return error.response.data;
    }
};

// ListAppUsers

export const ListAppUsers = async () => {
    try {
        const res = await axios.get("/otogas/Station/AttendantsList");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// deactivate user

export const DeactivateUser = async (userCode) => {
    try {
        const res = await axios.post(
            `/otogas/Authentication/DeactivateUser?userCode=${userCode}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// activate user
export const ActivateUser = async (userCode) => {
    try {
        // Authentication/ActivateUser?userCode=00006'
        const res = await axios.post(
            `/otogas/Authentication/ActivateUser?userCode=${userCode}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// send otp
export const SendOTP = async (msisdn) => {
    // 'GET' \
    // 'https://os.protoenergy.com/otogas/Authentication/SendOTP?phoneNumber=0715357867' 
    try {
        console.log(msisdn, "msisdn");
        const res = await axios.get(
            `otogas/Authentication/SendOTP?phoneNumber=${msisdn}`,
        );
        console.log(res, "res");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// forgot password

export const ChangePassword = async (formData) => {
    try {
        const res = await axios.post(
            "/otogas/Authentication/ChangePassword",
            formData,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// List Apps
export const ListApps = async () => {
    try {
        const res = await axios.get("/otogas/Authentication/GetAllApps");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const assignUserApps = async (userId, appId) => {
    try {
        const res = await axios.post(
            `/otogas/Authentication/AssignUserToApp?userCode=${userId}&appCpde=${appId}`,
        );

        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

// roles
export const list_roles = async () => {
    try {
        const res = await axios.get("/otogas/UserRoles/get-all-permisions");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const list_user_roles = async (user_id) => {
    try {
        const res = await axios.get(
            `/otogas/UserRoles/get-user-roles/${user_id}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const assign_roles = async (user_id, role_id) => {
    try {
        const res = await axios.post(
            `/otogas/UserRoles/assign-role-to-user?userId=${user_id}&roleId=${role_id}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const remove_roles = async (user_id, role_id) => {
    try {
        const res = await axios.post(
            `/otogas/UserRoles/remove-role-from-user?userId=${user_id}&roleId=${role_id}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const users_assigned_to_role = async (role_id) => {
    try {
        const res = await axios.get(
            `/otogas/UserRoles/get-users-assigned-to-role/${role_id}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const get_report_recipients = async (reportId) => {
    try {
        const res = await axios.get(
            `/otogas/Setup/GetRecipients?reportCode=${reportId}`,
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const add_report_recipient = async (reportId, recipient, type) => {
    console.log(reportId, recipient, type, "reportId, recipient, type");
    try {
        const res = await axios.post(`/otogas/Setup/AddRecipient?email=${recipient}&reportCode=${reportId}&type=${type}`);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const remove_report_recipient = async (reportId, email) => {
    // otogas/Setup/RemoveRecipient?email=3&reportCode=3'
    try {
        const res = await axios.post(
            `/otogas/Setup/RemoveRecipient?email=${email}&reportCode=${reportId}`,
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const get_reports = async () => {
    try {
        const res = await axios.get("/otogas/Setup/Reports");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const send_bulk_sms = async (message, sender, formData) => {
    try {
        const res = await axios.post(`/otogas/Messaging/SendBulkSms?message=${message}&sender=${sender}`, formData, {
            headers: {
                // No need to set 'Content-Type', Axios will set it automatically for FormData
            },
        });
        return res.data;
    } catch (error) {
        return error.response ? error.response.data : error.message;
    }
};


export const get_sms_sender_names = async () => {
    try {
        const res = await axios.get("/otogas/Messaging/GetSenderNames");
        // console.log(res, "res");
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}
