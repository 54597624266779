import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const FileUploader = ({ uploadFunction, name, allowed_file_types }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      setLoading(true);
      try {
        await uploadFunction(file);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
        setShowModal(false); // Close modal after upload
      }
    } else {
      alert('Please select a file first.');
    }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      {/* Button to open the modal */}
      <button className="btn btn-primary m-1" onClick={openModal}>
        {name}
      </button>

      {/* Modal for file selection */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select a file to upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="file"
              onChange={handleFileChange}
              accept={allowed_file_types.join(',')} // Restricting allowed file types
            />
          </Form.Group>
          <small>Allowed file types: {allowed_file_types.join(', ')}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Uploading...
              </>
            ) : (
              'Upload'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileUploader;
