import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Form } from "react-bootstrap";
import StationsContext from "../../context/otogas/stations/stationsContext";
import CustomerContext from "../../context/otogas/customers/customersContext";

const UpdateProductPriceForm = ({ stationCode, show, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const stationsContext = useContext(StationsContext);
  const customersContext = useContext(CustomerContext);
  const { setStationPrice } = stationsContext;
  const { products, listProducts } = customersContext;

  useEffect(() => {
    listProducts();
  }, []);

  const onSubmit = (data) => {
    setStationPrice([
      {
        productCode: data.productCode,
        newPrice: data.newPrice,
        stationCode: stationCode,
      },
    ]);
    handleClose(); // Close the modal after form submission
  };

  const productCodeOptions = products.map((product) => ({
    value: product.productCode,
    label: product.productName,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Product Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Product</label>
            <select
              {...register("productCode", {
                required: "Product Code is required",
              })}
              className={`form-control ${errors.productCode ? "is-invalid" : ""}`}
            >
              <option value="">Select Product</option>
              {productCodeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors.productCode && (
              <div className="text-danger mt-1">
                {errors.productCode.message}
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="newPrice">New Price</label>
            <input
              id="newPrice"
              type="number"
              className={`form-control ${errors.newPrice ? "is-invalid" : ""}`}
              {...register("newPrice", {
                required: "New price is required",
                min: {
                  value: 0,
                  message: "Price must be greater than or equal to 0",
                },
              })}
            />
            {errors.newPrice && (
              <div className="invalid-feedback">{errors.newPrice.message}</div>
            )}
          </div>

          {/* If needed, the stationCode can be automatically passed or made optional */}
          {/* <div className="form-group">
            <label htmlFor="stationCode">Station Code</label>
            <input
              id="stationCode"
              type="text"
              className={`form-control ${errors.stationCode ? 'is-invalid' : ''}`}
              {...register('stationCode', { required: 'Station code is required' })}
            />
            {errors.stationCode && (
              <div className="invalid-feedback">{errors.stationCode.message}</div>
            )}
          </div> */}

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProductPriceForm;
