import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import Main from '../../components/Layout/Main'

import Spinner from '../../components/Layout/spinner'

const ListTrucks = () => {
  const [trucks, setTrucks] = useState([])
  const [loading, setLoading] = useState(false)
  
  const getTrucks = async () => {

  try {
      const res = await fetch("https://pythonitruck.azurewebsites.net/trucks")
      const data = await res.json()
      setTrucks(data.trucks)
      setLoading(false)
  }
      catch(err) {
        console.log(err)
        setLoading(false)
      }
  }

  useEffect(() => {
    getTrucks()
  }, [])

  const page = 'List Trucks'

  const [search, setSearch] = useState('')
  const onSearch = (e) => {

    setSearch(e.target.value)
    console.log(search)
  }

  return (
    <div className="niceadmin">
      <Header /> 
      <Sidebar page={page} />
      <Main>
        {trucks.length > 0 && !loading ? (

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Trucks Table</h5>
              <input type="text" className="form-control" id="search" onChange={onSearch} name="search" placeholder="Search" />

              <table className="table table-responsive ">
                <thead>

                  <tr >
                    <th scope="col">#</th>
                    <th scope="col">Number Plate</th>
                    <th scope="col">LastLatitude</th>
                    <th scope="col">LastLongitude</th>

                  </tr>
                </thead>
                <tbody>

                  {
                    trucks
                      
                      .filter((truck) => {
                        return search.toLowerCase() === '' ? trucks :
                          truck.Plate.toLowerCase().includes(search.toLowerCase())
                      })

                      .map((truck) => (
                        <tr key={truck.Id}>

                          <td key={truck.Id}> {trucks.indexOf(truck) + 1} </td>
                          <td> {truck.Plate} </td>
                          <td> {truck.LastLatitude} </td>
                          <td> {truck.LastLongitude} </td>

                          <td>

                          </td>
                        </tr>
                      )
                      )
                  }

                </tbody>

              </table>
            </div>
          </div>) : (
          <Spinner />
        )}
      </Main>
    </div>

  )
}

export default ListTrucks