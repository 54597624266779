import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios"; // Assuming axios is used for other API calls
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import StationsContext from "../../context/otogas/stations/stationsContext";
import { messageBalance } from "../../api/otogas";

const BulkSmsSender = () => {
    const [step, setStep] = useState(1);
    const [organization, setOrganization] = useState("");
    const [message, setMessage] = useState("");
    const [messageLength, setMessageLength] = useState(0);
    const [messagesCount, setMessagesCount] = useState(1);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [balance, setBalance] = useState(null); // State for storing balance
    const [isRefreshingBalance, setIsRefreshingBalance] = useState(false); // State for loader on balance refresh
    const MAX_CHARACTERS = 640;
    const CHARACTERS_PER_MESSAGE = 160;
    const initialHeight = 50; // Starting height in pixels for the textarea

    const stationsContext = useContext(StationsContext);
    const { sms_sender_names, getSenderNames, sendBulkMessages } = stationsContext;
    const textareaRef = useRef(null);

    useEffect(() => {
        getSenderNames();
    }, []);

    useEffect(() => {
        setMessageLength(message.length);
        setMessagesCount(Math.ceil(message.length / CHARACTERS_PER_MESSAGE));
        setError(message.length > MAX_CHARACTERS);

        if (textareaRef.current) {
            adjustTextareaHeight();
        }
    }, [message]);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = `${initialHeight}px`; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
    };

    useEffect(() => {
        if (responseMessage && success) {
            const timer = setTimeout(() => {
                setResponseMessage("");
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [responseMessage, success]);

    const handleOrganizationChange = async (e) => {
        const selectedOrg = e.target.value;
        setOrganization(selectedOrg);

        if (selectedOrg) {
            await fetchBalance(selectedOrg);
        } else {
            setBalance(null); // Reset balance if no organization is selected
        }
    };

    const fetchBalance = async (org) => {
        setIsRefreshingBalance(true);
        try {
            const { balance: orgBalance } = await messageBalance(org);
            setBalance(orgBalance);
        } catch (error) {
            console.error("Failed to fetch balance:", error);
            setBalance(null); // Reset balance on error
        } finally {
            setIsRefreshingBalance(false);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleNextStep = (e) => {
        e.preventDefault();
        if (!organization) {
            alert("Please select an organization.");
            return;
        }
        setStep(2);
    };

    const handlePreviousStep = (e) => {
        e.preventDefault();
        setStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!message || !file || error) {
            alert("Please enter a message, upload a file, and ensure the character limit is not exceeded.");
            return;
        }

        const formData = new FormData();
        formData.append("phoneNumber", file);
        formData.append("message", message);
        formData.append("sender", organization);

        setIsLoading(true);
        setSuccess(false);

        try {
            await sendBulkMessages(message, organization, formData);
            setResponseMessage("Bulk SMS sent successfully.");
            setSuccess(true);
            resetForm();
        } catch (error) {
            setResponseMessage("Failed to send Bulk SMS. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setMessage("");
        setFile(null);
        setOrganization("");
        setStep(1);
    };

    return (
        <div className="bulk-sms-sender">
            <Header />
            <Sidebar />
            <Main>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8 col-sm-12">
                            {step === 1 && (
                                <>
                                    <h2 className="text-center">Select Organization</h2>
                                    <form onSubmit={handleNextStep}>
                                        <div className="form-group">
                                            <label>Select Organization:</label>
                                            <select
                                                value={organization}
                                                onChange={handleOrganizationChange}
                                                className="form-control"
                                            >
                                                <option value="">--Select Organization--</option>
                                                {sms_sender_names.map((org) => (
                                                    <option key={org} value={org}>
                                                        {org}
                                                    </option>
                                                ))}
                                            </select>
                                            {balance !== null && (
                                                <p className="mt-2">Credit Balance: {balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            )}
                                        </div>
                                        <div className="text-center mt-3">
                                            <button type="submit" className="btn btn-primary">
                                                Next
                                            </button>
                                        </div>
                                    </form>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h2 className="text-center">Send Bulk SMS ({organization})</h2>
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => fetchBalance(organization)}
                                            disabled={!organization || isRefreshingBalance}
                                        >
                                            {isRefreshingBalance ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                `Credit Balance: ${balance !== null ? balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "N/A"}`
                                            )}
                                        </button>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>Message:</label>
                                            <textarea
                                                ref={textareaRef}
                                                value={message}
                                                onChange={handleMessageChange}
                                                placeholder="Enter your message"
                                                className={`form-control ${error ? 'border border-danger' : ''}`}
                                                style={{ overflow: "hidden", resize: "none" }}
                                            ></textarea>
                                            <small className={`text-muted ${error ? "text-danger" : ""}`}>
                                                {messageLength} / {MAX_CHARACTERS} characters | {messagesCount} message{messagesCount > 1 ? "s" : ""}
                                                {error && <span> - Limit exceeded!</span>}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label>Phone Numbers File:</label>
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                onChange={handleFileChange}
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="d-flex justify-content-between mt-3">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={handlePreviousStep}
                                            >
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={isLoading || error}
                                            >
                                                {isLoading ? (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Sending...
                                                    </>
                                                ) : (
                                                    "Send Bulk SMS"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </>
                            )}

                            {responseMessage && (
                                <p className="text-center mt-3">{responseMessage}</p>
                            )}

                            {success && (
                                <div className="text-center mt-3">
                                    <button className="btn btn-secondary" onClick={resetForm}>
                                        Reset Form
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default BulkSmsSender;
