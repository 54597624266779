import React, { useState,useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import CustomersContext from '../../context/otogas/customers/customersContext';

const UpdateTotalizersModal = ({ show, handleClose, initialValues }) => {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();
  const [readings, setReadings] = useState(initialValues || []);
  const customersContext = useContext(CustomersContext);
  const { adjustStockTake } = customersContext; 
  

  // Set initial values when the modal is opened
  useEffect(() => {
    if (initialValues) {
      setValue('shiftNumber', initialValues.shiftNumber);
      setReadings(initialValues); // Set initial values to readings
    }
  }, [initialValues, setValue]);

  const onFormSubmit = (data) => {
    const formData = {
      shiftNumber: data.shiftNumber,
      readings:
        [{
          nozzleCode: data.nozzleCode,
          openingReading: data.openingReading,
          closingReading: data.closingReading
        }]
    };
    onSubmit(formData);
    handleClose(); // Close the modal on submit
  };

  const onSubmit = (formData) => {
    adjustStockTake(formData);
    console.log('Form data:', formData);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Nozzle Readings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Form.Group>
              <Form.Label>Shift Number</Form.Label>
              <Controller
                name="shiftNumber"
                control={control}
                defaultValue={initialValues?.shiftNumber || ''}
                render={({ field }) => (
                  <Form.Control {...field} readOnly />
                )}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Nozzle Code</Form.Label>
              <Controller
                name="nozzleCode"
                control={control}
                defaultValue={readings.nozzleCode || ''}
                rules={{ required: 'Nozzle code is required' }}
                render={({ field }) => (
                  <Form.Control {...field} isInvalid={errors?.nozzleCode} />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.nozzleCode?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Opening Reading</Form.Label>
              <Controller
                name="openingReading"
                control={control}
                defaultValue={readings.openingReading || ''}
                rules={{
                  required: 'Opening reading is required',
                  validate: (value) => value >= 0 || 'Reading must be a positive number'
                }}
                render={({ field }) => (
                  <Form.Control {...field} type="number" isInvalid={errors?.openingReading} />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.openingReading?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Closing Reading</Form.Label>
              <Controller
                name="closingReading"
                control={control}
                defaultValue={readings.closingReading || ''}
                rules={{
                  required: 'Closing reading is required',
                  validate: (value) =>
                    value >= readings.openingReading || 'Closing reading must be greater than opening reading'
                }}
                render={({ field }) => (
                  <Form.Control {...field} type="number" isInvalid={errors?.closingReading} />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.closingReading?.message}
              </Form.Control.Feedback>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleSubmit(onFormSubmit)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateTotalizersModal;
