import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  Table,
  Container,
  Row,
  Col,
  // Spinner,
  Button,
  Modal,
  Dropdown,
  DropdownButton,
  ButtonGroup
} from "react-bootstrap";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import AddCustomerVehicle from "./AddCustomerVehicle";
import CustomersContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import VehicleTopupModal from "./topupCustomer";
import UpdateVehicleModal from "./updateVehicle";
import ChangeVehicleStatusModal from "./changeStatus";
import TransferVehicleModal from "./transferVehicle";
import UpdateCustomerModal from "./updateCustomer";
import TransferAmountModal from "./transferWalletBalance";
import { set } from "date-fns";
import { download_customer_statement, download_cutomer_wallet_topup_history } from "../../api/otogas";
import DownloadButton from '../../components/util/downloader';
import Spinner from '../../components/Layout/spinner';

const CustomerSummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const customerContext = useContext(CustomersContext);
  const {
    fetchVehicles,
    vehicles,
    notification,
    clear_notification,
    uninstallVehicle,
    customer_vehicles,
    getCustomerVehicles,
  } = customerContext;
  const customerCode = location.state?.customerCode;
  // Destructure the customer object from customer_vehicles if it exists

  let customer = customer_vehicles?.customer;

  useEffect(() => {
    if (customerCode) {
      getCustomerVehicles(customerCode);
    }
  }, [customerCode]); // Fetch customer vehicles when customer is set

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showUninstallModal, setShowUninstallModal] = useState(false);
  const [selectedRegNo, setSelectedRegNo] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleCode, setSelectedVehicleCode] = useState("");
  const [selectedVehicleStatus, setSelectedVehicleStatus] = useState("");
  const [vehicleToUninstall, setVehicleToUninstall] = useState(null);
  const [showCustomerUpdateModal, setShowCustomerUpdateModal] =
    useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showTransferAmountModal, setShowTransferAmountModal] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [type, setType] = useState("pdf"); // Default type


  useEffect(() => {
    if (notification) {
      setAlertMessage({
        text: notification.text,
        type: notification.type,
      });
      setShowAlert(true);
    }
  }, [notification]);

  const handleConfirm = () => {
    setShowAlert(false);
    clear_notification();
    // reload the page
    getCustomerVehicles(customerCode);
  };

  const handleCloseCustomerUpdateModal = () =>
    setShowCustomerUpdateModal(false);
  const handleCloseAddVehicle = () => setShowAddVehicleModal(false);
  const handleAddVehicle = () => setShowAddVehicleModal(true);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedVehicle(null);
  };
  const handleCloseTopupModal = () => {
    setShowTopupModal(false);
    setSelectedRegNo("");
  };
  const handleCloseChangeStatusModal = () => {
    setShowChangeStatusModal(false);
    setSelectedVehicleCode("");
    setSelectedVehicleStatus("");
  };
  const handleCloseUninstallModal = () => {
    setShowUninstallModal(false);
    setVehicleToUninstall(null);
  };

  const handleUninstallVehicle = () => {
    if (vehicleToUninstall) {
      uninstallVehicle(vehicleToUninstall.vehicleCode);
      handleCloseUninstallModal();
    }
  };

  const handleCloseTransferAmountModal = () => {
    setShowTransferAmountModal(false);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
    setSelectedVehicle(null);
  };

  const handle_downloads = async (func, setLoadingSummary) => {
    setLoadingSummary(true);
    try {
      const res = await func();
      if (res === "File not found") {
        setAlertMessage({
          text: "No transactions found",
          type: "info",
        });
        setShowAlert(true);
      } else {
        // Handle file download logic here
        return res;
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
      setAlertMessage({
        text: "An error occurred while downloading the file",
        type: "danger",
      });
      setShowAlert(true);
    } finally {
      setLoadingSummary(false);
    }
  };

  if (!customer) {
    return (
      <div>
        <Header />
        <Sidebar page="Add Vehicle" />
        <Main>
          <Container>
            <Row>
              <Spinner animation="border" /> Loading...
            </Row>
          </Container>
        </Main>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Sidebar page="Add Vehicle" />
      <Main>
        <UpdateCustomerModal
          show={showCustomerUpdateModal}
          handleClose={handleCloseCustomerUpdateModal}
          customer={customer}
        />
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type || "info"}
        />

        {/* Topup Modal */}
        {showTopupModal && (
          <VehicleTopupModal
            show={showTopupModal}
            handleClose={handleCloseTopupModal}
            vehicle={selectedVehicle}
          />
        )}

        {showTransferAmountModal &&

          <TransferAmountModal
            show={showTransferAmountModal}
            handleClose={handleCloseTransferAmountModal}
            customerCode={customerCode}
          />

        }

        {/* Update Modal */}
        {showUpdateModal && (
          <UpdateVehicleModal
            show={showUpdateModal}
            handleClose={handleCloseUpdateModal}
            selected_vehicle={selectedVehicle}
          />
        )}

        {/* Change Status Modal */}
        {showChangeStatusModal && (
          <ChangeVehicleStatusModal
            show={showChangeStatusModal}
            handleClose={handleCloseChangeStatusModal}
            vehicleCode={selectedVehicleCode}
            status={selectedVehicleStatus}
          />
        )}

        {/* Transfer Vehicle Modal */}
        <TransferVehicleModal
          show={showTransferModal}
          handleClose={handleCloseTransferModal}
          vehicle={selectedVehicle}
          customer={customer}
        />

        {/* Uninstall Confirmation Modal */}
        <Modal
          show={showUninstallModal}
          onHide={handleCloseUninstallModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Uninstall</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to uninstall the vehicle with Reg
            No: {vehicleToUninstall?.vehicleRegistrationNumber}?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseUninstallModal}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setSelectedVehicle(vehicleToUninstall);
                handleUninstallVehicle();
              }}
            >
              Uninstall Vehicle
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>
          <Col>
            <h2>Customer Summary</h2>
            {customer_vehicles && customer_vehicles.customer && (
              <>
                <p>
                  <strong>Name:</strong>{" "}
                  {customer_vehicles.customer.customerName}
                </p>
                <p>
                  <strong>Phone:</strong>{" "}
                  {customer_vehicles.customer.customerPhone}
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  {customer_vehicles.customer.customerEmail}
                </p>
                <p>
                  <strong>KRA Pin:</strong>{" "}
                  {customer_vehicles.customer.kraPin}
                </p>
                <p>
                  <strong>Identification Number:</strong>{" "}
                  {
                    customer_vehicles.customer
                      .identificationNumber
                  }
                </p>
                <p>
                  <strong>Date Created:</strong>{" "}
                  {new Date(
                    customer_vehicles.customer.dateCreated,
                  ).toLocaleDateString()}
                </p>

                <p>
                  {" "}
                  <strong>Wallet Balance:</strong>{" "}
                  {customer_vehicles.totalBalance}
                </p>
                <p>
                  {" "}
                  <strong> Total Assets: </strong>{" "}
                  {customer_vehicles.totalVehicles}
                </p>
              </>
            )}

            <div className="d-flex justify-content-between">
              <div>
                <Button
                  className="btn btn-primary"
                  onClick={handleAddVehicle}
                >
                  Add Vehicle
                </Button>
                <Button
                  className="btn btn-secondary m-2"
                  onClick={() =>
                    setShowCustomerUpdateModal(true)
                  }
                >
                  Update Customer
                </Button>

                <Button
                  className="btn btn-primary m-2"
                  onClick={() => {
                    setShowTransferAmountModal(true);
                  }}
                >
                  Transfer Wallet Balance
                </Button>

                <DropdownButton
                  as={ButtonGroup}
                  id="download-type-dropdown"
                  title={
                    loadingSummary ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        {" "}Downloading...
                      </>
                    ) : "Download Statement"
                  }
                  onSelect={(eventKey) =>
                    handle_downloads(() => download_customer_statement(customer.customerCode, eventKey)
                      , setLoadingSummary
                    )
                  }
                  disabled={loadingSummary}
                  variant="secondary"
                  className="m-2"
                >
                  <Dropdown.Item eventKey="pdf">Download as PDF</Dropdown.Item>
                  <Dropdown.Item eventKey="excel">Download as Excel</Dropdown.Item>
                </DropdownButton>


                <DownloadButton
                  className="btn btn-secondary"
                  existingFunction={download_cutomer_wallet_topup_history}
                  buttonName="Export Topup History"
                  reportType="range"
                />



              </div>
              <Button
                variant="secondary"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>

            <h3>Customer Vehicles</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Vehicle Code</th>
                  <th>Registration Number</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Tank Capacity</th>
                  <th>Status</th>
                  <th>Balance</th>
                  <th>Date Created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {customer_vehicles &&
                  customer_vehicles.vehicles &&
                  customer_vehicles.vehicles.length > 0 ? (
                  customer_vehicles.vehicles.map(
                    (vehicle) => (
                      <tr key={vehicle.vehicleCode}>
                        <td>{vehicle.vehicleCode}</td>
                        <td>
                          {vehicle.vehicleRegistrationNumber ||
                            "N/A"}
                        </td>
                        <td>{vehicle.vehicleMake}</td>
                        <td>{vehicle.vehicleModel}</td>
                        <td>{vehicle.tankCapacity}</td>
                        <td>
                          <span
                            className={`badge ${vehicle.isActive ? "bg-success" : "bg-danger"}`}
                          >
                            {vehicle.isActive
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td>
                        <td>{vehicle.balance}</td>
                        <td>
                          {new Date(
                            vehicle.dateCreated,
                          ).toLocaleDateString()}
                        </td>
                        <td>
                          <div className="dropdown">

                            <div className="dropdown">
                              <button
                                className="btn btn-custom-actions dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Actions
                              </button>
                              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                  <Link
                                    className="dropdown-item d-flex align-items-center"
                                    state={{ vehicle: vehicle }}
                                    to={`/otogas/vehicles/${vehicle.vehicleCode}`}
                                    onClick={() => {
                                      setSelectedVehicle(vehicle);
                                    }
                                    }
                                  >
                                    <i className="bi bi-person"></i>{" "}
                                    <span>
                                      Statement
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item d-flex align-items-center"
                                    state={{ vehicle: vehicle }}
                                    to={`/otogas/vehicles/${vehicle.vehicleCode}/history`}
                                  >
                                    <i className="bi bi-wallet"></i>{" "}
                                    <span>
                                      Fueling
                                      History
                                    </span>
                                  </Link>
                                </li>
                                {/* <li>
                                <Link
                                  className="dropdown-item d-flex align-items-center"
                                  onClick={() => {
                                    setShowTopupModal(
                                      true,
                                    );
                                    setSelectedVehicle(
                                      vehicle,
                                    );
                                  }}
                                >
                                  <i className="bi bi-wallet"></i>{" "}
                                  <span>
                                    Sales
                                  </span>
                                </Link>
                              </li> */}
                                <li>
                                  <a
                                    className="dropdown-item d-flex align-items-center"
                                    onClick={() => {
                                      setShowUninstallModal(
                                        true,
                                      );
                                      setVehicleToUninstall(
                                        vehicle,
                                      );
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>{" "}
                                    <span>
                                      Uninstall
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item d-flex align-items-center"
                                    onClick={() => {
                                      setShowTransferModal(
                                        true,
                                      );
                                      setSelectedVehicle(
                                        vehicle,
                                      );
                                    }}
                                  >
                                    <i className="bi bi-arrow-right"></i>{" "}
                                    <span>
                                      Transfer
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  {vehicle.isActive ? (
                                    <a
                                      className="dropdown-item d-flex align-items-center"
                                      onClick={() => {
                                        setShowChangeStatusModal(
                                          true,
                                        );
                                        setSelectedVehicleCode(
                                          vehicle.vehicleCode,
                                        );
                                        setSelectedVehicleStatus(
                                          "deactivate",
                                        );
                                      }}
                                    >
                                      <i className="bi bi-toggle2-off"></i>{" "}
                                      <span>
                                        Deactivate
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      className="dropdown-item d-flex align-items-center"
                                      onClick={() => {
                                        setShowChangeStatusModal(
                                          true,
                                        );
                                        setSelectedVehicleCode(
                                          vehicle.vehicleCode,
                                        );
                                        setSelectedVehicleStatus(
                                          "activate",
                                        );
                                      }}
                                    >
                                      <i className="bi bi-toggle2-on"></i>{" "}
                                      <span>
                                        Activate
                                      </span>
                                    </a>
                                  )}
                                </li>


                                <li>
                                  <a
                                    className="dropdown-item d-flex align-items-center"
                                    onClick={() => {
                                      setSelectedVehicle(
                                        vehicle,
                                      );
                                      setShowUpdateModal(
                                        true,
                                      );
                                    }}
                                  >
                                    <i className="bi bi-gear"></i>{" "}
                                    <span>
                                      Edit
                                      Vehicle
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                  )
                ) : (
                  <tr>
                    <td colSpan="8">
                      No vehicles found for this customer.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>

        {showAddVehicleModal && (
          <AddCustomerVehicle
            show={showAddVehicleModal}
            handleClose={handleCloseAddVehicle}
            customerId={customer.customerCode}
          />
        )}
      </Main>
    </div>
  );
};

export default CustomerSummaryPage;
