import React, {useEffect,useContext} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import CustomersContext from '../../context/otogas/customers/customersContext';
function AddProductModal({ show, handleClose }) {

  const customersContext = useContext(CustomersContext);
  const { addProduct, products, listProducts, addPaymentType, changeStationPrice, notification, clear_notification, setGlobalPricing } = customersContext;
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleFormSubmit = (data) => {
    console.log(data);   
    addProduct(data);
    reset(); // Reset the form after submission
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Product</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formProductName">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product name"
              {...register('productName', { required: 'Product name is required' })}
              isInvalid={!!errors.productName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.productName?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Product
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddProductModal;
