import React, { useEffect, useContext, useState } from "react";
import { Table, Row, Col, Card, Container, Button, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import CustomersContext from "../../context/otogas/customers/customersContext";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';


const VehicleFuelingEvents = () => {
    const { vehicleCode } = useParams();
    const customersContext = useContext(CustomersContext);
    const { vehicle_fueling_events, getVehicleFuelingEvents } = customersContext;
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [eventsPerPage] = useState(10);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (vehicleCode) {
            getVehicleFuelingEvents(vehicleCode);
        }
    }, [vehicleCode]);

    useEffect(() => {
        // Filter and search functionality
        const filtered = vehicle_fueling_events
            .filter((event) => {
                const matchesSearch = event.stationName.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesPaymentType = selectedPaymentType ? event.paymentTypeName === selectedPaymentType : true;
                return matchesSearch && matchesPaymentType;
            });
        setFilteredEvents(filtered);
    }, [vehicle_fueling_events, searchTerm, selectedPaymentType]);

    const handleBackClick = () => {
        navigate("/otogas/vehicles");
    };

    // Pagination logic
    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle payment type filter
    const handlePaymentTypeChange = (e) => {
        setSelectedPaymentType(e.target.value);
    };

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Vehicles" />
            <Main>

                {/* Back Button */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                    
                    
                    <Button variant="secondary" onClick={handleBackClick}>
                        Back to Vehicles
                    </Button>
                </div>

                {/* Search and Filter Form */}
                <Row className="my-3">
                    <Col md={6}>
                        <Form.Control
                            type="text"
                            placeholder="Search by Station Name"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Form.Control as="select" value={selectedPaymentType} onChange={handlePaymentTypeChange}>
                            <option value="">Filter by Payment Type</option>
                            {Array.from(new Set(vehicle_fueling_events.map((event) => event.paymentTypeName)))
                                .map((paymentType, index) => (
                                    <option key={index} value={paymentType}>
                                        {paymentType}
                                    </option>
                                ))}
                        </Form.Control>
                    </Col>
                </Row>

                {/* Vehicle Details Card */}
                <Row className="my-4">
                    <Col>
                        <Card>
                            <Card.Body>
                                <h5>Vehicle Details</h5>
                                <p><strong>Vehicle Registration:</strong> {vehicle_fueling_events.length > 0 ? vehicle_fueling_events[0].vehicleRegistrationNumber : "N/A"}</p>
                                <p><strong>Total Fueling Events:</strong> {filteredEvents.length}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Fueling Events Table */}
                <Card className="mb-4">
                    <Card.Body>
                        <h4>Fueling Events</h4>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Station</th>
                                    <th>Nozzle</th>
                                    <th>Dispenser</th>
                                    <th>Quantity (Liters)</th>
                                    <th>Amount (KES)</th>
                                    <th>Payment Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentEvents.length > 0 ? (
                                    currentEvents.map((sale, index) => (
                                        <tr key={index}>
                                            <td>{new Date(sale.dateCreated).toLocaleString()}</td>
                                            <td>{sale.stationName}</td>
                                            <td>{sale.nozzleName}</td>
                                            <td>{sale.dispenserName}</td>
                                            <td>{sale.quantity.toFixed(2)}</td>
                                            <td>{sale.amount}</td>
                                            <td>{sale.paymentTypeName}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No fueling events available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        {/* Pagination */}
                        <div className="pagination">
                            {Array.from({ length: Math.ceil(filteredEvents.length / eventsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                                <Button
                                    key={pageNumber}
                                    variant="outline-primary"
                                    className="mx-1"
                                    onClick={() => paginate(pageNumber)}
                                    active={currentPage === pageNumber}
                                >
                                    {pageNumber}
                                </Button>
                            ))}
                        </div>
                    </Card.Body>
                </Card>
            </Main>
        </div>
    );
};

export default VehicleFuelingEvents;
