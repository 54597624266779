import React, { useState, useRef } from 'react';
import { Container, Row, Col, Table, Button, Card, Form } from 'react-bootstrap';
import Header from '../../components/Layout/Header';
import Main from '../../components/Layout/Main';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import Sidebar from '../../components/Layout/Sidebar';
import CustomerContext from "../../context/otogas/customers/customersContext";

const products = [
    { value: 'product1', label: 'Product 1', price: 10 },
    { value: 'product2', label: 'Product 2', price: 15 },

];



const PointOfSale = () => {

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [customer, setCustomer] = useState({ name: '', phone: '', email: '' });
    const componentRef = useRef();

    const handleProductSelect = (selectedOption) => {
        const existingProduct = selectedProducts.find(
            (product) => product.value === selectedOption.value
        );

        if (existingProduct) {
            setSelectedProducts(
                selectedProducts.map((product) =>
                    product.value === selectedOption.value
                        ? { ...product, quantity: product.quantity + 1 }
                        : product
                )
            );
        } else {
            setSelectedProducts([
                ...selectedProducts,
                { ...selectedOption, quantity: 1 },
            ]);
        }
    };

    const handleRemoveProduct = (productToRemove) => {
        setSelectedProducts(
            selectedProducts.filter((product) => product.value !== productToRemove.value)
        );
    };

    const handleQuantityChange = (productToUpdate, increment) => {
        setSelectedProducts(
            selectedProducts.map((product) =>
                product.value === productToUpdate.value
                    ? { ...product, quantity: Math.max(1, product.quantity + increment) }
                    : product
            )
        );
    };

    const totalQuantity = selectedProducts.reduce((sum, product) => sum + product.quantity, 0);
    const totalPrice = selectedProducts.reduce((sum, product) => sum + product.quantity * product.price, 0);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prev) => ({ ...prev, [name]: value }));
    };

    const handleSell = () => {
        alert('Sale successful! Invoice printed.');
        // Further logic for finalizing the sale can be added here (e.g., API call to save sale record)
        setSelectedProducts([]);
        setCustomer({ name: '', phone: '', email: '' });
    };


    return (
        <div>
            <Header />
            <Sidebar page="efr" />
            <Main>
                <div fluid className="mt-4">
                    <Row>
                        <Col md={6}>
                            <h5>Customer Details</h5>
                            <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={customer.name}
                                        onChange={handleInputChange}
                                        placeholder="Enter customer name"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={customer.phone}
                                        onChange={handleInputChange}
                                        placeholder="Enter customer phone"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={customer.email}
                                        onChange={handleInputChange}
                                        placeholder="Enter customer email"
                                    />
                                </Form.Group>
                            </Form>

                            <h5>Select Product</h5>
                            <Select
                                options={products}
                                onChange={handleProductSelect}
                                placeholder="Select a product"
                            />
                            <Table striped bordered hover className="mt-4">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.map((product) => (
                                        <tr key={product.value}>
                                            <td>{product.label}</td>
                                            <td>{product.quantity}</td>
                                            <td>${product.price * product.quantity}</td>
                                            <td>
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={() => handleQuantityChange(product, -1)}
                                                    disabled={product.quantity <= 1}
                                                >
                                                    -
                                                </Button>{' '}
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={() => handleQuantityChange(product, 1)}
                                                >
                                                    +
                                                </Button>{' '}
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleRemoveProduct(product)}
                                                >
                                                    Remove
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>

                        <Col md={4}>
                            <Card ref={componentRef}>
                                <Card.Body>
                                    <Card.Title>Invoice Summary</Card.Title>
                                    <p><strong>Customer:</strong> {customer.name || 'N/A'}</p>
                                    <p><strong>Phone:</strong> {customer.phone || 'N/A'}</p>
                                    <p><strong>Email:</strong> {customer.email || 'N/A'}</p>
                                    <hr />
                                    <p><strong>Total Items:</strong> {totalQuantity}</p>
                                    <p><strong>Total Price:</strong> ${totalPrice}</p>
                                    {/* <ReactToPrint
                 trigger={() => <Button variant="outline-success" className="mt-2">Print Invoice</Button>}
                 content={() => componentRef.current}
               /> */}
                                    <Button variant="primary" onClick={handleSell} className="mt-2">
                                        Sell
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </Main>
        </div>
    )
}

export default PointOfSale;
