import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import CustomersContext from "../../context/otogas/customers/customersContext";
import debounce from 'lodash.debounce';



const TransferVehicleModal = ({ show, handleClose, vehicle }) => {
  const { transferVehicle, customers, getCustomers } = useContext(CustomersContext);
  const [customerCode, setCustomerCode] = useState('');

  useEffect(() => {
    if (show) {
      // getCustomers();
      // Check if vehicle is defined and set the customerCode accordingly
      if (vehicle && vehicle.customerCode) {
        setCustomerCode(vehicle.customerCode);
      }
    }
  }, [show, getCustomers, vehicle]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);

  // Parameters for pagination and search
  const page = 1; // Example: static page number
  const page_size = 10; // Example: static page size
  const [customerPhone, setCustomerPhone] = useState('');

  // Debounce function to fetch customers after a delay
  const debouncedFetchCustomers = debounce((inputValue) => {
    getCustomers(page, page_size, inputValue, customerPhone);
  }, 500); // 500ms debounce delay

  // Effect to update options once customers data changes
  useEffect(() => {
    console.log("called get customers")
    if (customers) {
      const options = customers.map((customer) => ({
        value: customer.customerCode,
        label: `${customer.customerName} (${customer.customerPhone})`,
      }));
      setCustomerOptions(options);
    }
  }, [customers]);

  // Handle selection of a customer
  const handleChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setCustomerCode(selectedOption.value);
  };

  // Handle input changes for search
  const handleInputChange = (inputValue) => {
    debouncedFetchCustomers(inputValue); // Call the debounced function
  };

  const handleTransfer = () => {
    if (vehicle) {
      transferVehicle({ customerCode, vehicleCode: vehicle.vehicleCode });
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Display current vehicle details if available */}
          {vehicle ? (
            <Form.Group controlId="vehicleDetails">
              <Form.Label>Vehicle Details</Form.Label>
              <Form.Control
                type="text"
                value={`Vehicle: ${vehicle.vehicleRegistrationNumber} - Current Customer: ${vehicle.customerName}`}
                readOnly
              />
            </Form.Group>
          ) : (
            <p>Loading vehicle details...</p> // Display this if vehicle is null or undefined
          )}

          {/* Customer dropdown */}
          <Form.Group controlId="customerCode" className="mt-3">
            <Form.Label>Select New Customer</Form.Label>
            {/* <Select
              options={customerOptions}
              onChange={(selectedOption) => setCustomerCode(selectedOption.value)}
              value={customerOptions.find((option) => option.value === customerCode)} // Pre-select current customer
              placeholder="Select a customer"
              isDisabled={!vehicle} // Disable if vehicle is not loaded
            /> */}

            <Select
              value={selectedCustomer}
              onChange={handleChange}
              onInputChange={handleInputChange}
              options={customerOptions}
              placeholder="Search for a customer"
              isDisabled={!vehicle} // Disable if vehicle is not loaded
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleTransfer} disabled={!vehicle}>
          Transfer Vehicle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferVehicleModal;
