import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap's modal
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import StationsContext from "../../context/otogas/stations/stationsContext";

const FormField = ({ id, label, register, errors, validation }) => (
    <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <input
            type="text"
            id={id}
            className={`form-control ${errors[id] ? "is-invalid" : ""}`}
            {...register(id, validation)}
        />
        {errors[id] && (
            <div className="invalid-feedback">{errors[id].message}</div>
        )}
    </div>
);

function AddTillModal({ show, onHide, till }) {
    const stationsContext = useContext(StationsContext);
    const { addTill, updateTill, notification, clear_notification } =
        stationsContext;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    const isEditMode = !!till; // Check if till is provided, indicating edit mode

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    // Prefill the form when in edit mode
    useEffect(() => {
        if (isEditMode && till) {
            reset({
                tillNumber: till.tillNumber,
                storeNumber: till.storeNumber,
                tillName: till.tillName,
            });
        } else {
            reset(); // Reset form when adding a new till
        }
    }, [isEditMode, till, reset]);

    const handleConfirm = () => {
        if (notification && notification.type === "success") {
            reset();
            onHide();
        }
        setShowAlert(false);
        clear_notification();
    };

    const onSubmit = (data) => {
        if (isEditMode) {
            updateTill(till.id, data); // Update the existing till
        } else {
            addTill(data); // Add new till
        }
    };

    return (
        <>
            <SweetAlertWrapper
                show={showAlert}
                title="Notification"
                message={alertMessage.text}
                onConfirm={handleConfirm}
                confirmBtnText="OK"
                type={alertMessage.type || "info"}
            />

            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditMode ? "Edit Till" : "Add Till"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormField
                            id="tillNumber"
                            label="Till Number"
                            register={register}
                            errors={errors}
                            validation={{ required: "Till number is required" }}
                        />
                        <FormField
                            id="storeNumber"
                            label="Store Number"
                            register={register}
                            errors={errors}
                            validation={{
                                required: "Store number is required",
                            }}
                        />
                        <FormField
                            id="tillName"
                            label="Till Name"
                            register={register}
                            errors={errors}
                            validation={{ required: "Till name is required" }}
                        />
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="secondary"
                                onClick={onHide}
                                className="mr-2"
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                                {isEditMode ? "Update Till" : "Save Till"}
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddTillModal;
