import React, { useEffect, useContext, useState, useRef } from "react";
import { Table, Button, Row, Col, Form, Card, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CustomersContext from "../../context/otogas/customers/customersContext";
import { useParams } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import "react-datepicker/dist/react-datepicker.css";
import VehicleTopupModal from "./topupCustomer";
import SetCreditLimitModal from "./setCreditLimit";
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";

import {
  download_vehicle_statement,
  download_vehicle_statement_pdf,
} from "../../api/otogas";

const TransactionPage = () => {
  const regNo = useParams().vehicleCode;
  const customersContext = useContext(CustomersContext);
  const {
    vehicle_summary,
    getVehicleSummary,
    notification,
    clear_notification,
  } = customersContext;
  const location = useLocation();
  const vehicle = location.state.vehicle
  const navigate = useNavigate();

  // Using one useState for date range (array of startDate and endDate)
  const [dateRange, setDateRange] = useState([]);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [showSetCreditLimitModal, setShowSetCreditLimitModal] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

  const handleCloseTopupModal = () => setShowTopupModal(false);
  const handleCloseSetCreditLimitModal = () =>
    setShowSetCreditLimitModal(false);

  const handleConfirmAlert = () => {
    setShowAlert(false);
    clear_notification();

    // Extract startDate and endDate from dateRange for fetching
    fetchVehicleSummary();
  };

  const handle_downloads = async (func, setLoading) => {
    setLoading(true);
    try {
      const res = await func();
      if (res === "File not found") {
        setAlertMessage({
          text: "No transactions found",
          type: "info",
        });
        setShowAlert(true);
      } else {
        // Handle file download logic here
        return res;
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
      setAlertMessage({
        text: "An error occurred while downloading the file",
        type: "danger",
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicleSummary = async () => {
    if (dateRange[0] && dateRange[1]) {
      const [startDate, endDate] = dateRange;
      await getVehicleSummary(
        regNo,
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
      );
    } else {
      // Fetch initial data using current date + 1 as endDate and one month ago as startDate
      const today = new Date();
      const oneMonthAgo = new Date(today);
      oneMonthAgo.setMonth(today.getMonth() - 1);
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1); // Set to tomorrow
      await getVehicleSummary(
        regNo,
        oneMonthAgo.toISOString().split("T")[0],
        tomorrow.toISOString().split("T")[0],
      );
    }
  };

  // Fetch vehicle summary when the dateRange or regNo changes
  useEffect(() => {
    fetchVehicleSummary();
  }, [regNo, dateRange]);

  useEffect(() => {
    if (notification) {
      setAlertMessage({
        text: notification.text,
        type: notification.type,
      });
      setShowAlert(true);
    }
  }, [notification]);

  // Handle date range selection
  const handleDateChange = (dates) => {
    setDateRange(dates); // dates will be an array [startDate, endDate]
  };

  // Handle click outside the date picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDatePicker &&
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirmAlert}
          confirmBtnText="OK"
          type={alertMessage.type || "info"}
        />
        {showTopupModal && (
          <VehicleTopupModal
            show={showTopupModal}
            handleClose={handleCloseTopupModal}
            vehicle={vehicle}
          />
        )}

        {showSetCreditLimitModal && (
          <SetCreditLimitModal
            show={showSetCreditLimitModal}
            handleClose={handleCloseSetCreditLimitModal}
            vehicle={vehicle}
          />
        )}
        {vehicle && (
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <Card.Title>
                {vehicle.vehicleRegistrationNumber}
              </Card.Title>
              <Card.Text>
                <strong>Customer:</strong>{" "}
                {vehicle.customerName} <br />
                <strong>Customer Phone:</strong>{" "}
                {vehicle.customerPhone} <br />
                <strong>Customer Email:</strong>{" "}
                {vehicle.customerEmail} <br />
                <strong>Conversion Date:</strong>{" "}
                {new Date(
                  vehicle.conversionDate,
                ).toLocaleDateString()}{" "}
                <br />
              </Card.Text>
              <Button
                variant="primary"
                onClick={() => navigate(`/otogas/vehicles`)}
              >
                Back
              </Button>
            </Card.Body>
          </Card>
        )}
        <div className="card shadow-sm">
          <div className="card-body">
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group className="d-flex align-items-center">
                  <Form.Label className="me-3 mb-0">
                    Date Range:
                  </Form.Label>
                  <Button
                    variant="outline-primary"
                    onClick={() =>
                      setShowDatePicker(!showDatePicker)
                    }
                    className="me-2"
                  >
                    Select Date Range
                  </Button>
                </Form.Group>
                {showDatePicker && (
                  <div
                    className="position-relative"
                    ref={datePickerRef}
                  >
                    <DatePicker
                      selected={dateRange[0]} // Start date
                      onChange={handleDateChange} // Handle both start and end date
                      startDate={dateRange[0]} // Start date
                      endDate={dateRange[1]} // End date
                      selectsRange // Enable range selection
                      inline
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                )}
              </Col>
              <Col md={6} className="text-end">
                <Button
                  className="btn btn-primary m-1"
                  onClick={() =>
                    handle_downloads(() => download_vehicle_statement(regNo), setLoadingExcel)
                  }
                  disabled={loadingExcel} // Disable button when loading
                >
                  {loadingExcel ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Downloading Excel...
                    </>
                  ) : (
                    "Download Statement Excel"
                  )}
                </Button>


                <Button
                  className="btn btn-secondary m-1"
                  onClick={() =>
                    handle_downloads(() => download_vehicle_statement_pdf(regNo), setLoadingPDF)
                  }
                  disabled={loadingPDF} // Disable button when loading
                >
                  {loadingPDF ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Downloading PDF...
                    </>
                  ) : (
                    "Download PDF"
                  )}
                </Button>

                <Button
                  variant="primary"
                  onClick={() =>
                    setShowTopupModal(true)
                  }
                >
                  Top-up
                </Button>

                <Button
                  variant="secondary"
                  className="ms-2"
                  onClick={() =>
                    setShowSetCreditLimitModal(true)
                  }
                >
                  Credit Limit
                </Button>
              </Col>
            </Row>

            <Table striped bordered hover responsive>
              <thead className="table-light">
                <tr>
                  <th>Description</th>
                  <th>Credit</th>
                  <th>Debit</th>
                  <th>Running Balance</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {vehicle_summary.length > 0 ? (
                  vehicle_summary.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.description}</td>
                      <td>{transaction.credit}</td>
                      <td>{transaction.debit}</td>
                      <td>
                        {transaction.runningBalance}
                      </td>
                      <td>
                        {new Date(
                          transaction.dateCreated,
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No transactions found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default TransactionPage;
