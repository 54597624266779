import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Button, Modal } from 'react-bootstrap';
import StationsContext from '../../context/otogas/stations/stationsContext';

const AssignTillToDispenser = ({ show, handleClose, tillNumber }) => {
    const stationsContext = useContext(StationsContext);
    const { dispensers, listDispensers, assignTillToDispenser } = stationsContext;

    const { handleSubmit, reset } = useForm();
    const [selectedDispenser, setSelectedDispenser] = useState(null);

    useEffect(() => {
        // Fetch dispensers when component mounts
        listDispensers();
    }, []);
    const dispenserOptions = dispensers.map(dispenser => ({
       
        value: dispenser.dispenserCode,
        label: `${dispenser.stationName} - ${dispenser.dispenserName}`
    }));

    const onSubmit = () => {
        const dispenserCode = selectedDispenser ? selectedDispenser.value : null;
        console.log(dispenserCode);
        console.log(tillNumber);

        if (tillNumber && dispenserCode) {
            assignTillToDispenser({ tillNumber, dispenserCode });
            setSelectedDispenser(null);
            handleClose(); // Close the modal after successful submission
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Assign Till to Dispenser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label>Dispenser Code</label>
                        <Select
                            value={selectedDispenser}
                            onChange={setSelectedDispenser}
                            options={dispenserOptions}
                            placeholder="Select Dispenser Code"
                        />
                        {!selectedDispenser && <p className="text-danger">This field is required</p>}
                    </div>
                    <Button variant="primary" type="submit">
                        Assign Till to Dispenser
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AssignTillToDispenser;
