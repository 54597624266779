import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

function AddPaymentTypeModal({ show, handleClose, onSubmit }) {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleFormSubmit = (data) => {
    onSubmit(data.paymentTypeName);
    reset(); // Reset form after successful submission
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Payment Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Form.Group controlId="paymentTypeName">
            <Form.Label>Payment Type Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter payment type name"
              {...register('paymentTypeName', { required: 'Payment type name is required' })}
              isInvalid={!!errors.paymentTypeName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.paymentTypeName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Payment Type
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddPaymentTypeModal;
