import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import StationsContext from "../context/otogas/stations/stationsContext";
import spinner from "./Layout/spinner";

function Dashboard() {
  const stationsContext = useContext(StationsContext);
  const { dashboard_data, getDashboardData } = stationsContext;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      getDashboardData().then(() => setLoading(false));
    }, 60);
  }, []);

  if (loading) {
    return spinner();
  }

  if (!dashboard_data) {
    return <div>No data available</div>;
  }

  // Sales Quantities Chart
  const salesSeries = [
    {
      name: "Sales Quantities (L)",
      data: [dashboard_data.thisWeekSoldQuantities || 0, dashboard_data.lastWeekSoldQuantities || 0],
    },
  ];

  const salesOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: "Sales Quantities This Week vs Last Week",
      style: {
        color: "#333",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["This Week", "Last Week"],
    },
    colors: ["#1E90FF"], // Blue line color
  };

  // Top Performing Stations Chart
  const topStationsSeries = [
    {
      name: "Quantity Sold (L)",
      data: (dashboard_data.topPerformingStations || []).map((station) => station.quantitySold || 0),
    },
  ];

  const topStationsOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: "Top Performing Stations Last 7 Days",
      style: {
        color: "#333",
      },
    },
    xaxis: {
      categories: (dashboard_data.topPerformingStations || []).map((station) => station.stationName || "Unknown"),
    },
    colors: ["#28B463"], // Green bar color
  };

  // Vehicle Activity Pie Chart
  const vehicleActivitySeries = [dashboard_data.totalVehicles || 0]; // Assuming all vehicles are active

  const vehicleActivityOptions = {
    chart: {
      type: "pie",
    },
    labels: ["Active Vehicles"],
    colors: ["#FF6347"], // Tomato color for the pie chart
    title: {
      text: "Vehicle Activity",
      style: {
        color: "#333",
      },
    },
  };

  return (
    <Container fluid>
      <h2 className="my-4">Real-Time Dashboard</h2>
      <Row>
        {/* Summary Cards */}
        <Col md={3}>
          <Card className="text-center" style={{ backgroundColor: "#1E90FF", color: "white" }}>
            <Card.Body>
              <Card.Title>Total Customers</Card.Title>
              <Card.Text>{dashboard_data.totalCustomers || 0}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center" style={{ backgroundColor: "#28B463", color: "white" }}>
            <Card.Body>
              <Card.Title>Total Vehicles</Card.Title>
              <Card.Text>{dashboard_data.totalVehicles || 0}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center" style={{ backgroundColor: "#FF6347", color: "white" }}>
            <Card.Body>
              <Card.Title>Sales Today</Card.Title>
              <Card.Text>{dashboard_data.salesToday || 0} L</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center" style={{ backgroundColor: "#546E7A", color: "white" }}>
            <Card.Body>
              <Card.Title>Sales This Week</Card.Title>
              <Card.Text>{dashboard_data.thisWeekSoldQuantities || 0} L</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Alert for No Sales Today */}
      {dashboard_data.salesToday === 0 && (
        <Alert variant="warning" className="mt-4">
          <strong>No sales recorded for today.</strong>
        </Alert>
      )}

      <Row className="my-4">
        {/* Sales Quantities Chart */}
        <Col md={6}>
          <Card>
            <Card.Body>
              <ReactApexChart
                options={salesOptions}
                series={salesSeries}
                type="line"
                height={350}
              />
            </Card.Body>
          </Card>
        </Col>

        {/* Top Performing Stations Chart */}
        <Col md={6}>
          <Card>
            <Card.Body>
              <ReactApexChart
                options={topStationsOptions}
                series={topStationsSeries}
                type="bar"
                height={350}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="my-4">
        {/* Vehicle Activity Chart */}
        <Col md={6}>
          <Card>
            <Card.Body>
              <ReactApexChart
                options={vehicleActivityOptions}
                series={vehicleActivitySeries}
                type="pie"
                height={350}
              />
            </Card.Body>
          </Card>
        </Col>

        {/* Served Vehicles Today */}
        <Col md={6}>
          <Card className="text-center" style={{ backgroundColor: "#1E90FF", color: "white" }}>
            <Card.Body>
              <Card.Title>Served Vehicles Today</Card.Title>
              <Card.Text>{dashboard_data.totalServedVehiclesToday || 0}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
