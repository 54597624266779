import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom"; // or whatever routing library you're using
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import { GetUser } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";

import UserContext from "../../context/users/userContext"
import AuthContext from "../../context/auth/authContext";

const RegisterUsers = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming you're using react-router-dom
  const userContext = useContext(UserContext);
  const { register, updateUser, selected_user, notification, clear_notifications } = userContext // Assuming getUser fetches user data
  const authContext = useContext(AuthContext);
  const { list_apps, apps } = authContext;
  const page = "Add User";

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  // sweet alert confirmation
  const handleConfirm = () => {
    setShowAlert(false);
    // if (notification.type === "success") {
    //   navigate('/users');
    // }
    clear_notifications()
  };
  // listen to notification change
  useEffect(() => {
  if (notification && notification.text && notification.type) {
    console.log('Notification:', notification);
    setAlertMessage({ text: notification.text, type: notification.type });
    setShowAlert(true);
  }
}, [notification]);


  useEffect(() => {
    list_apps()
  }, [])


  const getUser = async (userCode) => {
    let selected_user = await GetUser(userCode)
    return selected_user
  }

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    middName: "",
    email: "",
    phoneNumber: "",
    payrollNumber: "",
    accessApps: [],
  });
  console.log(user)
  useEffect(() => {
    if (id) {
      // Fetch user data for editing
      const fetchUserData = async () => {
        try {
          const userData = await getUser(id);



          if (userData) {
            setUser({
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              middName: userData.middName || "",
              email: userData.email || "",
              phoneNumber: userData.phoneNumber || "",
              payrollNumber: userData.payrollNumber || "",
              accessApps: userData.accessApps || [],
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [id]); // Only run this effect when `id` changes


  const onChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const onSelectChange = selectedOptions => {
    const selectedAppsCodes = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setUser(prevUser => ({
      ...prevUser,
      accessApps: selectedAppsCodes
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateUser(id, user);
    } else {
      register(user);
    }
  };

  const appOptions = apps.map(app => ({
    value: app.appsCode,
    label: app.appsName
  }));

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page={page} />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type || "info"}
        />
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="align-center">
                      <h5 className="card-title">{id ? "Edit User" : "Add User"}</h5>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <form className="needs-validation" onSubmit={onSubmit} noValidate>
                  <div className="row mb-3">
                    <label htmlFor="firstName" className="col-sm-3 col-form-label">First Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={user.firstName}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="middName" className="col-sm-3 col-form-label">Middle Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="middName"
                        name="middName"
                        value={user.middName}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="lastName" className="col-sm-3 col-form-label">Last Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={user.lastName}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="email" className="col-sm-3 col-form-label">Email Address</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={user.email}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="phoneNumber" className="col-sm-3 col-form-label">Phone Number</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={user.phoneNumber}
                        onChange={onChange}
                        maxLength={10}
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="payrollNumber" className="col-sm-3 col-form-label">Payroll Number</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="payrollNumber"
                        name="payrollNumber"
                        value={user.payrollNumber}
                        onChange={onChange}
                        maxLength={10}
                        required
                      />
                    </div>
                  </div>

                  {/* <div className="row mb-3">
                    <label htmlFor="accessApps" className="col-sm-3 col-form-label">Access Apps</label>
                    <div className="col-sm-9">
                      <Select
                        isMulti
                        name="accessApps"
                        options={appOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={appOptions.filter(option => user.accessApps.includes(option.value))}
                        onChange={onSelectChange}
                      />
                    </div>
                  </div> */}

                  <div className="bx-pull-right">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </Main>
    </div>
  );
};

export default RegisterUsers;