import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Badge,
  Card,
  Button,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import StationsContext from "../../context/otogas/stations/stationsContext";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import AddOrUpdateNozzleModal from "../dispensers/addNozzle";
import AddDispenser from "../dispensers/addDispenser";
import AssignUserDispenser from "../dispensers/assignDispenser";
import AddTank from "../stations/addTank";
import InitialStockTakeModal from "../stock/setInitialStock";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import Select from "react-select";
import AddPDAtoDispenserModal from "../stations/RegisterPda";
import UpdateProductPriceForm from "../settings/priceChange";

import userCan from "../../config/user_can";
import AuthContext from "../../context/auth/authContext";

const StationOverview = () => {
  const location = useLocation();
  const { station } = location.state || {}; // Get the station data from the location state
  const navigate = useNavigate();
  const stationsContext = useContext(StationsContext);
  const stationCode = useParams().stationCode;
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const {
    current_station_dispensers,
    nozzles,
    listStationDispensers,
    listNozzles,
    current_station_tanks,
    current_station_assignments, // Assignments data from context
    listStationTanks,
    stationAssignments,
    notification,
    clear_notification,
  } = stationsContext;

  const [selectedDispenser, setSelectedDispenser] = useState(null);
  const [showAssignUserDispenserModal, setShowAssignUserDispenserModal] =
    useState(false);
  const [showAddNozzleModal, setShowAddNozzleModal] = useState(false);
  const [showAddDispenserModal, setShowAddDispenserModal] = useState(false);
  const [showAddTankModal, setShowAddTankModal] = useState(false);
  const [showInitialStockTakeModal, setShowInitialStockTakeModal] =
    useState(false);
  const [showAddPDAtoDispenserModal, setShowAddPDAtoDispenserModal] =
    useState(false);
  const [showPriceUpdateModal, setShowPriceUpdateModal] = useState(false);
  const [selectedNozzleCode, setSelectedNozzleCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

  const handleClose = () => {
    setShowAddNozzleModal(false);
    setShowAddDispenserModal(false);
    setShowAssignUserDispenserModal(false);
    setShowAddTankModal(false);
    setShowInitialStockTakeModal(false);
    setShowAddPDAtoDispenserModal(false);
    setShowPriceUpdateModal(false);
  };

  const handleConfirm = () => {
    setShowAlert(false);
    clear_notification();
    listStationDispensers(stationCode);
    listStationTanks(stationCode);
    stationAssignments(stationCode);
  };

  useEffect(() => {
    if (stationCode) {
      listStationDispensers(stationCode);
      listNozzles(); // Fetch all nozzles once
      listStationTanks(stationCode);
      stationAssignments(stationCode); // Fetch station assignments
    }
  }, [stationCode]);

  useEffect(() => {
    if (notification) {
      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);

  const handleAddNozzle = () => {
    setShowAddNozzleModal(true);
  };

  const handleAddDispenser = () => {
    setShowAddDispenserModal(true);
  };

  const handlePriceUpdate = (stationCode) => {
    setShowPriceUpdateModal(true);
  };

  // Filter nozzles by dispenserCode
  const getFilteredNozzles = (dispenserCode) => {
    return nozzles.filter((nozzle) => nozzle.dispenserCode === dispenserCode);
  };

  // Global filter for selecting dispenser
  const handleDispenserSelect = (selectedOption) => {
    setSelectedDispenser(selectedOption);
  };

  const dispenserOptions = current_station_dispensers.map((dispenser) => ({
    value: dispenser.dispenserCode,
    label: dispenser.dispenserName,
  }));

  return (
    <div className="station-overview-page">
      <Header />
      <Sidebar />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type || "info"}
        />
        {selectedDispenser && (
          <AddOrUpdateNozzleModal
            show={showAddNozzleModal}
            handleClose={handleClose}
            dispenserCode={selectedDispenser.value}
          />
        )}

        <AddDispenser
          show={showAddDispenserModal}
          handleClose={handleClose}
          stationCode={stationCode}
        />
        <UpdateProductPriceForm
          show={showPriceUpdateModal}
          handleClose={handleClose}
          stationCode={stationCode}
        />

        {selectedDispenser && (
          <AddPDAtoDispenserModal
            show={showAddPDAtoDispenserModal}
            handleClose={handleClose}
            dispenserCode={selectedDispenser.value}
          />
        )}
        <InitialStockTakeModal
          show={showInitialStockTakeModal}
          handleClose={handleClose}
          nozzleCode={selectedNozzleCode}
        />
        {selectedDispenser && (
          <AssignUserDispenser
            show={showAssignUserDispenserModal}
            handleClose={handleClose}
            stationCode={stationCode}
            dispenserCode={selectedDispenser.value}
          />
        )}
        <AddTank
          show={showAddTankModal}
          handleClose={handleClose}
          stationCode={stationCode}
        />

        <div className="container-fluid my-5">
          {/* Station Information Card */}
          <Card className="station-info-card mb-4">
            <Card.Header>
              Station Name: <strong>{station && station.stationName}</strong>
            </Card.Header>
            <Card.Body>
              <p className="card-text">
                Address: {station && station.stationAddress}
              </p>
              <p className="card-text">
                Date Created:{" "}
                {station && new Date(station.dateCreated).toLocaleDateString()}
              </p>
              <p className="card-text">
                Status:
                <Badge
                  bg={
                    station && station.status === "Active"
                      ? "success"
                      : "danger"
                  }
                  className="ms-2"
                >
                  {station && station.status}
                </Badge>
              </p>
            </Card.Body>
          </Card>

          {/* Station Pricing Section */}
          {station && station.prices && (
            <Card className="station-pricing-card mb-4">
              <Card.Header>
                <h5>Station Pricing</h5>
              </Card.Header>
              <Card.Body>
                <Table striped hover>
                  <thead>
                    <tr>
                  
                      <th>Product Name</th>
                      <th>Price (Amount)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {station.prices.map((price) => (
                      <tr key={price.productCode}>
                        <td>{price.productName}</td>
                        <td>{price.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}

          {/* Station Actions */}
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Dropdown className="station-actions-dropdown">
              <Dropdown.Toggle variant="primary" id="dropdown-global-actions">
                <i className="bi bi-gear"></i> Station Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* Change Status item, disabled if user doesn't have permission */}
                <Dropdown.Item
                  onClick={() => console.log("Change Status")}
                  // disabled={!userCan(user, 'POST:Stations:ChangeStatus')}
                >
                  <i className="bi bi-toggle-on me-2"></i> Change Status
                </Dropdown.Item>

                {/* Add Dispenser item, disabled if user doesn't have permission */}
                <Dropdown.Item
                  onClick={handleAddDispenser}
                  disabled={!userCan(user, "POST:Station:AddDispenser")}
                >
                  <i className="bi bi-plus me-2"></i> Add Dispenser
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => handlePriceUpdate(stationCode)}
                  // disabled={!userCan(user, 'POST:Station:AddPDAtoDispenser')}
                >
                  Change Station Price
                </Dropdown.Item>

                {/* Add Tank item, disabled if user doesn't have permission */}
                <Dropdown.Item
                  onClick={() => setShowAddTankModal(true)}
                  disabled={!userCan(user, "POST:Station:AddTank")}
                >
                  <i className="bi bi-plus me-2"></i> Add Tank
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              variant="secondary"
              onClick={() => navigate("/otogas/stations")}
            >
              <i className="bi bi-arrow-left me-2"></i> Back
            </Button>
          </div>

          {/* Tabs for Dispensers, Tanks, and Assignments */}
          <Tabs defaultActiveKey="dispensers" id="station-overview-tabs">
            <Tab eventKey="dispensers" title="Dispensers">
              {/* Global Dispenser Filter */}
              <div className="mb-4">
                <Select
                  options={dispenserOptions}
                  onChange={handleDispenserSelect}
                  placeholder="Select a Dispenser"
                />
              </div>

              {/* Dispenser Summary and Nozzles */}
              {selectedDispenser && (
                <>
                  <Card className="dispenser-summary-card mb-4">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <div>
                        Dispenser Name:{" "}
                        <strong>{selectedDispenser.label}</strong>
                      </div>
                      {/* Dispenser Actions next to the name */}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-dispenser-actions"
                        >
                          <i className="bi bi-gear"></i> Dispenser Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handleAddNozzle}>
                            <i className="bi bi-plus me-2"></i> Add Nozzle
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() =>
                              setShowAssignUserDispenserModal(true)
                            }
                          >
                            <i className="bi bi-person-plus me-2"></i> Assign
                            Attendant
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => setShowAddPDAtoDispenserModal(true)}
                          >
                            <i className="bi bi-person-plus me-2"></i> Register
                            PDA
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Card.Header>
                    <Card.Body>
                      <p className="card-text">
                        Status:
                        <Badge
                          bg={
                            current_station_dispensers.find(
                              (d) =>
                                d.dispenserCode === selectedDispenser.value,
                            )?.status
                              ? "success"
                              : "danger"
                          }
                          className="ms-2"
                        >
                          {current_station_dispensers.find(
                            (d) => d.dispenserCode === selectedDispenser.value,
                          )?.status
                            ? "Active"
                            : "Inactive"}
                        </Badge>
                      </p>
                    </Card.Body>
                  </Card>

                  {/* Nozzles Table */}
                  <h2 className="mb-4">Nozzles</h2>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Nozzle Code</th>
                        <th>Nozzle Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getFilteredNozzles(selectedDispenser.value).map(
                        (nozzle) => (
                          <tr key={nozzle.nozzleCode}>
                            <td>{nozzle.nozzleCode}</td>
                            <td>{nozzle.nozzleName}</td>
                            <td>
                              <Badge bg={nozzle.status ? "success" : "danger"}>
                                {nozzle.status ? "Active" : "Inactive"}
                              </Badge>
                            </td>
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="nozzleActions"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Actions
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="nozzleActions"
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        setSelectedNozzleCode(
                                          nozzle.nozzleCode,
                                        );
                                        setShowInitialStockTakeModal(true);
                                      }}
                                      disabled={
                                        !userCan(
                                          user,
                                          "POST:Stock:InitialStockTake",
                                        )
                                      }
                                    >
                                      <i className="bi bi-pencil me-2"></i>{" "}
                                      Initial Stock Take
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </>
              )}
            </Tab>

            {/* Tanks Tab */}
            <Tab eventKey="tanks" title="Tanks">
              {/* Tanks Table */}
              <h2 className="mb-4">Tanks</h2>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Tank Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {current_station_tanks &&
                    current_station_tanks.map((tank) => (
                      <tr key={tank.id}>
                        <td>{tank.id}</td>
                        <td>{tank.tankName}</td>
                        <td>
                          <Badge bg={tank.status ? "success" : "danger"}>
                            {tank.status ? "Active" : "Inactive"}
                          </Badge>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Tab>

            {/* Assignments Tab */}
            <Tab eventKey="assignments" title="Assignments">
              <h2 className="mb-4">Assignments</h2>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Attendant Name</th>
                    <th>Dispenser Name</th>
                    <th>Station Name</th>
                    <th>Date Assigned</th>
                  </tr>
                </thead>
                <tbody>
                  {current_station_assignments &&
                    current_station_assignments.map((assignment) => (
                      <tr
                        key={
                          assignment.attendantName + assignment.dispenserName
                        }
                      >
                        <td>{assignment.attendantName}</td>
                        <td>{assignment.dispenserName}</td>
                        <td>{assignment.stationName}</td>
                        <td>
                          {new Date(assignment.dateAssigned).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </div>
      </Main>
    </div>
  );
};

export default StationOverview;
