import React, { useContext, useEffect } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import CustomersContext from "../../context/otogas/customers/customersContext";

const MpesaCodeUsage = ({ show, handleClose, transId }) => {
    const customersContext = useContext(CustomersContext);
    const { mpesaCodeUsage, code_sales } = customersContext;

    useEffect(() => {
        if (transId) {
            console.log("Fetching sales for:", transId);
            mpesaCodeUsage(transId);
        }
    }, [transId]);

    useEffect(() => {
    }, [code_sales]);


    return (
        <Modal show={show} onHide={handleClose} size="xl" >
            <Modal.Header closeButton>
                <Modal.Title>View Sales Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {code_sales && code_sales.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Vehicle</th>
                                <th>Shift Number</th>
                                <th>Sale ID</th>
                                <th>Station Name</th>
                                <th>Dispenser Name</th>
                                <th>Nozzle Name</th>
                                <th>Attendant Name</th>
                                <th>Litres</th>
                                <th>Amount</th>
                                <th>Sales Date</th>
                                <th>Price</th>
                                <th>Till Number</th>
                                <th>Transaction ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {code_sales.map((sale, index) => (
                                <tr key={index}>
                                    <td>{sale.vehicle}</td>
                                    <td>{sale.shiftNumber}</td>
                                    <td>{sale.saleId}</td>
                                    <td>{sale.stationName}</td>
                                    <td>{sale.dispenserName}</td>
                                    <td>{sale.nozzleName}</td>
                                    <td>{sale.attendantName}</td>
                                    <td>{sale.litres}</td>
                                    <td>{sale.amount}</td>
                                    <td>{new Date(sale.salesDate).toLocaleString()}</td>
                                    <td>{sale.price}</td>
                                    <td>{sale.tillNumber}</td>
                                    <td>{sale.transId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>No sales data available</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MpesaCodeUsage;
