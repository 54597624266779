import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  searchVehicle,
  searchEmployee,
  validatePaymentReference,
} from "../../api/otogas";
import CustomersContext from "../../context/otogas/customers/customersContext";
import debounce from "lodash.debounce";
import { set } from "date-fns";

// Config for payment types with rules for each
const paymentTypeConfig = {
  0: {
    requiresVehicle: true,
    requiresPaymentReference: true,
    label: "Mpesa",
    requiresEmployee: false,
    requiresPaymentValidation: true,
  },
  1: {
    requiresVehicle: true,
    requiresWalletCheck: true,
    label: "Wallet",
    requiresEmployee: false,
    requiresPaymentValidation: false,
  },
  2: {
    requiresVehicle: true,
    label: "new Conversions",
    requiresEmployee: false,
    requiresPaymentValidation: false,
  },
  3: {
    requiresEmployee: true,
    label: "Operational loss",
    requiresVehicle: false,
    requiresPaymentValidation: false,
  },
  4: {
    requiresEmployee: true,
    label: "Bank transfer",
    requiresVehicle: false,
    requiresPaymentValidation: false,
  },
  5: {
    requiresEmployee: true,
    requiresPaymentReference: true,
    label: "Salary",
    requiresVehicle: false,
    requiresPaymentValidation: true,
  },
  6: {
    requiresPaymentReference: true,
    label: "Insurance",
    requiresVehicle: false,
    requiresEmployee: true,
    requiresPaymentValidation: false,
  },
  7: {
    requiresPaymentReference: true,
    label: "Voucher",
    requiresVehicle: true,
    requiresEmployee: false,
    requiresPaymentValidation: true,
  },
  8: {
    requiresEmployee: true,
    label: "Calibration",
    requiresPaymentValidation: false,
  },
  8: {
    requiresEmployee: true,
    label: "Compesational fuel",
    requiresPaymentValidation: false,
  },
};

const VarianceClearanceModal = ({ show, handleClose, selectedVariance }) => {
  const [paymentType, setPaymentType] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [amountPayable, setAmountPayable] = useState(0);
  const [paymentReference, setPaymentReference] = useState("");
  const [comment, setComment] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [price, setPrice] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentReferenceValid, setPaymentReferenceValid] = useState(null);
  const [loading, setLoading] = useState(false);

  // Error states
  const [vehicleError, setVehicleError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [paymentReferenceError, setPaymentReferenceError] = useState("");

  const {
    vehicles,
    fetchVehicles,
    payment_types: paymentTypes,
    listPaymentTypes,
    addSale,
  } = useContext(CustomersContext);

  const [vehicleOptions, setVehicleOptions] = useState([]); // Define vehicleOptions state

  useEffect(() => {
    listPaymentTypes();
    resetForm();
  }, []);

  useEffect(() => {
    // Automatically fetch the price based on payment type
    if (paymentType || paymentType === 0) {
      const config = paymentTypeConfig[paymentType];
      if (config.requiresEmployee && selectedVariance?.userCode) {
        fetchPriceForUser(selectedVariance.userCode);
      } else if (config.requiresVehicle && vehicleDetails?.vehicleCode) {
        fetchPriceForVehicle(vehicleDetails.vehicleCode);
      }
    }
  }, [paymentType, selectedVariance?.userCode, vehicleDetails?.vehicleCode]);

  const fetchPriceForUser = async (userCode) => {
    setIsLoading(true);
    try {
      const priceData = await searchEmployee(userCode);
      if (priceData) {
        setPrice(priceData.price);
      } else {
        alert("Price not found or error occurred.");
      }
    } catch (error) {
      console.error("Error fetching price:", error);
    }
    setIsLoading(false);
  };

  const fetchPriceForVehicle = async (vehicleCode) => {
    setIsLoading(true);
    try {
      const vehicleData = await searchVehicle(
        selectedVariance?.stationCode,
        vehicleDetails.vehicleRegistrationNumber
      );

      if (vehicleData?.responseObject) {
        const price = vehicleData.responseObject.price;
        const walletBalance = vehicleData.responseObject.walletBalance || 0; // Ensure walletBalance is set to 0 if not returned

        if (price) {
          setPrice(price);
          setWalletBalance(walletBalance); // Set wallet balance even if it's 0
        } else {
          setVehicleError("Price not found or error occurred.");
        }
      } else {
        setVehicleError("Error fetching vehicle details.");
      }
    } catch (error) {
      setVehicleError("Error fetching price.");
      console.error("Error fetching price:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (price && quantity > 0) {
      const total = price * quantity;
      setAmountPayable(total.toFixed(2));

      if (paymentType === 1 && walletBalance !== null) {
        // Check if entered quantity exceeds wallet balance
        if (total > walletBalance) {
          setQuantityError("Entered quantity exceeds wallet balance");
          setQuantity(0); // Reset quantity to prevent exceeding balance
          setAmountPayable(0); // Reset amount payable
        } else {
          setQuantityError(""); // Clear error if within balance
        }
      } else {
        setQuantityError(""); // Clear any quantity error for other payment types
      }
    } else {
      setAmountPayable(0);
      setQuantityError(""); // Clear error if quantity is invalid
    }
  }, [quantity, price, walletBalance, paymentType]);

  const validatePayment = async () => {
    setPaymentReferenceError("");
    setPaymentReferenceValid(null);
    setIsLoading(true);

    try {
      // Await the response from validatePaymentReference
      const response = await validatePaymentReference(
        selectedVariance.dispenserCode,
        paymentReference
      );

      // Check if response is valid and the code is 1
      if (response && response.responseCode === 1) {
        const parsedAmountPayable = parseFloat(amountPayable); // Ensure amountPayable is a number
        const balanceUsage = parseFloat(response.responseObject.usageBalance); // Ensure balanceUsage is a number
        console.log("Parsed amount payable:", parsedAmountPayable);
        console.log("Balance usage:", balanceUsage);
        console.log((parsedAmountPayable <= balanceUsage))

        if (parsedAmountPayable <= balanceUsage) {

          setPaymentReferenceValid(response.responseMessage);
        } else {
          setPaymentReferenceError(response.responseMessage);
        }
      } else {
        setPaymentReferenceError(response.responseMessage);
      }
    } catch (error) {
      console.log("Error occurred during validation:", error);
      setPaymentReferenceValid("Error validating payment.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true); // Start loading
    e.preventDefault();
    let hasErrors = false;

    const config = paymentTypeConfig[paymentType];

    // If employee is required, use userCode as vehicleCode
    const vehicleCode = config.requiresEmployee
      ? selectedVariance?.userCode
      : vehicleDetails?.vehicleCode;

    if (!vehicleCode && config?.requiresVehicle) {
      setVehicleError("Vehicle registration is required.");
      hasErrors = true;
    } else {
      setVehicleError("");
    }

    if (quantity <= 0 && paymentType !== 3) {
      setQuantityError("Quantity must be greater than 0.");
      hasErrors = true;
    } else {
      setQuantityError("");
    }

    if (config?.requiresPaymentReference && paymentReference.trim() === "") {
      setPaymentReferenceError("Payment reference is required.");
      hasErrors = true;
    } else if (config?.requiresPaymentValidation && !paymentReferenceValid) {
      setPaymentReferenceError("Please validate the payment reference.");
      hasErrors = true;
    } else {
      setPaymentReferenceError("");
    }

    if (hasErrors) return;

    const payload = {
      vehicleCode, // This could be vehicleDetails or userCode based on the payment type
      paymentTypeCode: paymentType,
      nozzleCode: selectedVariance?.nozzleCode,
      shiftNumber: selectedVariance?.shiftNumber,
      quantity: parseFloat(quantity),
      dispenserCode: selectedVariance?.dispenserCode,
      comment,
      paymentDetails: [
        {
          transactionReference: paymentReference,
          transactionAmount: amountPayable,
        },
      ],
    };

    try {
      const response = await addSale(payload);
      if (response && response.error) {
        setPaymentReferenceError("Error submitting form: " + response.error.message);
      } else {
        resetForm();
        handleClose();
      }
    } catch (error) {
      setPaymentReferenceError("An unexpected error occurred. Please try again.");
    }
    finally {
      setLoading(false); // Stop loading in any case
    }
  };

  const resetForm = () => {
    setVehicleDetails(null);
    setQuantity(0);
    setAmountPayable(0);
    setPaymentReference("");
    setComment("");
    setPrice(0);
    setWalletBalance(null);
    setPaymentReferenceValid(null);
    setIsLoading(false);
    setPaymentReferenceError("");
    setVehicleError("");
    setQuantityError("");
    setVehicleOptions([]); // Clear the vehicle options

  };

  const handleChange = (selectedOption) => {
    setVehicleDetails({
      vehicleCode: selectedOption.value,
      vehicleRegistrationNumber: selectedOption.label,
    });
  };

  const debouncedFetchVehicles = debounce((regNo) => {
    fetchVehicles(1, 10, "", regNo, "", "", true);
  }, 500);

  useEffect(() => {
    if (vehicles) {
      const options = vehicles.map((vehicle) => ({
        value: vehicle.vehicleCode, 
        label: vehicle.vehicleRegistrationNumber,
      }));
      setVehicleOptions(options); // Properly update the vehicle options
    }
  }, [vehicles]);

  console.log(isLoading, "isLoading");
  console.log(paymentType, "paymentType");
  console.log(quantity, "quantity");
  console.log(vehicleError, "vehicleError");
  console.log(quantityError, "quantityError");
  console.log(comment, "comment");
  console.log(paymentReference, "paymentReference");
  const isSubmitDisabled =
    isLoading ||
    (paymentType === null || paymentType === undefined) || // Allow paymentType to be 0, but not null or undefined
    // !quantity && 
    vehicleError ||
    quantityError ||
    !comment.trim() || // Ensuring comment is not empty or whitespace
    paymentReferenceError;


  console.log(isSubmitDisabled, "isSubmitDisabled");
  console.log(paymentReferenceError, "paymentReferenceError");

  return (
    <Modal
      show={show}
      onHide={() => {
        resetForm();
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Variance Clearance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="paymentType">
            <Form.Label>Select Payment Type</Form.Label>
            <Select
              options={paymentTypes.map((type) => ({
                value: type.paymentTypeId,
                label: type.paymentTypeName.replace(/_/g, " "),
              }))}
              onChange={(selectedOption) => {
                resetForm();
                setPaymentType(selectedOption.value);
              }}
              placeholder="Select Payment Type"
            />
          </Form.Group>

          {/* Render appropriate fields based on payment type */}
          {paymentTypeConfig[paymentType]?.requiresVehicle && (
            <Form.Group controlId="vehicleRegistration">
              <Form.Label>Vehicle Registration</Form.Label>
              <Select
                options={vehicleOptions} // Use the state vehicleOptions
                onChange={handleChange}
                onInputChange={(inputValue) =>
                  debouncedFetchVehicles(inputValue)
                }
                placeholder="Select Vehicle"
                value={vehicleOptions.find(
                  (option) => option.value === vehicleDetails?.vehicleCode
                )}
                isClearable
              />
              {vehicleError && (
                <Form.Text className="text-danger">{vehicleError}</Form.Text>
              )}
              {isLoading && <Spinner animation="border" size="sm" />}
            </Form.Group>
          )}

          {/* Wallet Details Section - Shown when Wallet is the selected Payment Type */}
          {paymentTypeConfig[paymentType]?.requiresWalletCheck &&
            walletBalance !== null && (
              <Form.Group controlId="walletDetails">
                <Form.Label>Vehicle Wallet Details</Form.Label>
                <p>
                  <strong>Price per unit:</strong> {price}
                </p>
                <p>
                  <strong>Wallet Balance:</strong> {walletBalance}
                </p>
              </Form.Group>
            )}

          {/* Show Employee Details */}
          {paymentTypeConfig[paymentType]?.requiresEmployee &&
            selectedVariance && (
              <div>
                <p>
                  <strong>Name:</strong> {selectedVariance?.name || "N/A"}
                </p>
                <p>
                  <strong>User Code:</strong> {selectedVariance?.userCode || "N/A"}
                </p>
                {isLoading && <Spinner animation="border" size="sm" />}
              </div>
            )}

          {price && (
            <Form.Group controlId="quantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text" // Keep as text to allow '-' and decimals
                value={quantity}
                onChange={(e) => {
                  const value = e.target.value.trim(); // Trim to avoid invalid inputs
                  const parsedValue = parseFloat(value);

                  // Handle negative values and '-' for paymentType 3
                  if (paymentType === 3) {
                    if (value === "-" || value === "" || !isNaN(parsedValue)) {
                      setQuantityError("");
                      setQuantity(value); // Keep the value as string for now
                      setAmountPayable(
                        !isNaN(parsedValue) ? (parsedValue * price).toFixed(2) : "0"
                      ); // Calculate only when valid number
                    } else {
                      setQuantityError("Invalid input");
                    }
                  }
                  // For other payment types, allow only positive decimal values
                  else if (!isNaN(parsedValue) && parsedValue >= 0) {
                    setQuantityError("");
                    setQuantity(value); // Keep the value as a string to allow decimals
                    setAmountPayable((parsedValue * price).toFixed(2)); // Allow decimal calculation
                  } else {
                    setQuantityError("Quantity must be greater than 0");
                    setQuantity(""); // Reset the input
                    setAmountPayable("0");
                  }
                }}
                required
              />
              {quantityError && (
                <Form.Text className="text-danger">{quantityError}</Form.Text>
              )}
            </Form.Group>
          )}




          {amountPayable > 0 && (
            <Form.Group controlId="totalAmount">
              <Form.Label>Total Payable Amount</Form.Label>
              <p>
                <strong>{amountPayable}</strong>
              </p>
            </Form.Group>
          )}

          {/* Payment Reference */}
          {paymentTypeConfig[paymentType]?.requiresPaymentReference && (
            <Form.Group controlId="paymentReference">
              <Form.Label>Payment Reference</Form.Label>
              <Form.Control
                type="text"
                value={paymentReference}
                onChange={(e) => {
                  setPaymentReference(e.target.value);
                  // Reset the validation state when input changes
                  if (paymentReferenceValid !== null) {
                    setPaymentReferenceValid(null);
                  }
                }}
                required
              />

              {/* Error message */}
              {paymentReferenceError && (
                <div className="mt-2">
                  <Form.Text className="text-danger">
                    {paymentReferenceError}
                  </Form.Text>
                </div>
              )}

              {/* Validation message */}
              {paymentReferenceValid && (
                <div className="mt-2">
                  <Form.Text
                    className="text-success"
                  >
                    {paymentReferenceValid}
                  </Form.Text>
                </div>
              )}

              {/* Validation Button */}
              {paymentType !== null &&
                paymentType !== undefined &&
                paymentTypeConfig[paymentType]?.requiresPaymentValidation && (
                  <div className="mt-3">
                    <Button
                      onClick={validatePayment}
                      disabled={isLoading || paymentReferenceValid !== null}
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Validate Payment"
                      )}
                    </Button>
                  </div>
                )}
            </Form.Group>
          )}



          {/* Comment */}
          <Form.Group controlId="comment">
            <Form.Label>Comment</Form.Label>
            <Form.Control
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
          </Form.Group>

          {/* Submit Button */}
          {/* <Button variant="primary" type="submit" disabled={isSubmitDisabled}>
            Submit
          </Button> */}

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Submitting...
              </>
            ) : (
              'Submit'
            )}
          </Button>

        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VarianceClearanceModal;
