import React, { useContext, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomerContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";

const CustomerCreditLimitModal = ({ show, handleClose, customer }) => {
    const customerContext = useContext(CustomerContext);
    const { setCustomerCreditLimit } = customerContext;
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            customerCode: customer.customerCode, // Pre-fill the customer code with customerCode
        },
    });


    const onSubmit = (data) => {
        // Show the SweetAlert confirmation
        setFormData(data);
        setShowAlert(true);
    };

    const handleConfirm = () => {
        setShowAlert(false); // Close the alert


        // Ensure that the credit limit is sent as a number
        const creditLimit = parseFloat(formData.creditLimit);

        setCustomerCreditLimit({
            ...formData,
            customerCode: customer.customerCode,
            creditLimit, // Send the parsed number instead of string
        });

        handleClose(); 
    }

    const handleCancel = () => {
        setShowAlert(false); // Just close the alert
    };



    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Credit Limit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* Vehicle Code (Non-editable) */}
                        <Form.Group controlId="vehicleCode">
                            <Form.Label>Customer</Form.Label>
                            <Form.Control
                                type="text"
                                value={customer.customerName}
                                readOnly
                                {...register("customerCode")}
                            />
                        </Form.Group>

                        {/* Amount */}
                        <Form.Group controlId="amount" className="mt-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter credit limit"
                                {...register("creditLimit", {
                                    required: "Credit Limit is required",
                                    min: 1,
                                })}
                                isInvalid={errors.creditLimit}
                            />
                            {errors.creditLimit && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.creditLimit.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        {/* Submit Button */}
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <SweetAlertWrapper
                show={showAlert}
                title="Confirm Action"
                message={`Are you sure you want to set a credit limit for the customer ${customer.customerName}?`}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                confirmBtnText="Yes, set limit"
                cancelBtnText="No"
                showCancelButton={true}
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="secondary"
                type="warning"
            />

        </>
    );
};

export default CustomerCreditLimitModal;
