// Importing Libraries
import React from 'react';
import { useContext } from 'react';
// Importing Compponents
import Header from "../components/Layout/Header";
import Sidebar from "../components/Layout/Sidebar";
import Footer from "../components/Layout/Footer";
import Main from '../components/Layout/Main';
import Dashboard from '../components/Dashboard';
import AlertContext from '../context/alerts/alertContext';

// Importing Style
import './App.css';
import Alerts from '../components/Layout/Alerts';

function PageDashboard({nonActive}) {
  const page = 'Dashboard';
  
  const alertContext = useContext(AlertContext);
  return (
  <React.Fragment>
    <div className="niceadmin">
      <Header />
      <Sidebar page={page}/>
        <Main>
   
          <Dashboard />
        </Main>
      <Footer />
    </div>
  </React.Fragment>
  )
}

export default PageDashboard;