import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';  // Import react-select

import UserContext from '../../context/users/userContext';
import StationsContext from '../../context/otogas/stations/stationsContext';

const AssignUserDispenser = ({ show, stationCode, dispenserCode, handleClose }) => {
    const { app_users, list_app_users } = useContext(UserContext);
    const { assignUserToDispenser } = useContext(StationsContext);

    useEffect(() => {
        list_app_users();
    }, []);

    const [formData, setFormData] = useState({
        attendantUserCode: null  // react-select returns an object, so this should start as null
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (selectedOption) => {
        setFormData({
            ...formData,
            attendantUserCode: selectedOption,
            dispenserCode: dispenserCode,
            stationCode: stationCode
        });
    };

    useEffect(() => {
        setIsFormValid(!!formData.attendantUserCode);
    }, [formData]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (isFormValid) {
            handleSubmit(formData);
        }
    };

    const handleSubmit = (formData) => {
        assignUserToDispenser(
            {
                attedantUserCode: formData.attendantUserCode.value,
                dispenserCode: formData.dispenserCode,
                stationCode: formData.stationCode
            }
        );
        handleClose();
    }

    const userOptions = app_users.map(user => ({
        value: user.userCode,  // You can adjust the fields based on your data structure
        label: user.name
    }));

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Assign User Dispenser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="formAttendantUserCode">
                        <Form.Label>Select User</Form.Label>
                        <Select
                            value={formData.attendantUserCode}
                            onChange={handleChange}
                            options={userOptions}
                            placeholder="Select User"
                            isClearable
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={!isFormValid}>
                        Save
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AssignUserDispenser;
