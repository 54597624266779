import React, { useEffect, useContext, useState } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Link } from "react-router-dom";
import StationsContext from "../../context/otogas/stations/stationsContext";
import AssignTillToDispenser from "./assignTillToDispenser";
import AuthContext from "../../context/auth/authContext";

import AddTillModal from "./addTill";

import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import { Dropdown, DropdownButton } from "react-bootstrap";
import userCan from "../../config/user_can";

const ListTillNumbers = () => {
    const stationsContext = useContext(StationsContext);
    const {
        tills,
        listTills,
        changeTillStatus,
        notification,
        clear_notification,
    } = stationsContext;
    const authContext = useContext(AuthContext);
    const { user } = authContext;

    const [search, setSearch] = useState("");
    const [statusFilter, setStatusFilter] = useState("all");
    const [selectedTill, setSelectedTill] = useState(null);
    const [showAssignTillModal, setShowAssignTillModal] = useState(false);
    const [showAddTillModal, setShowAddTillModal] = useState(false);

    const handleClose = () => {
        setShowAssignTillModal(false);
        setShowAddTillModal(false);
    };

    useEffect(() => {
        listTills();
    }, []);

    const page = "List Till Numbers";

    const filteredList = tills.filter((till) => {
        const matchesSearch = till.tillName
            .toLowerCase()
            .includes(search.toLowerCase());
        const matchesStatus =
            statusFilter === "all" ||
            (statusFilter === "active" && till.isActive) ||
            (statusFilter === "inactive" && !till.isActive);

        return matchesSearch && matchesStatus;
    });

    const handleSearchChange = (e) => setSearch(e.target.value);
    const handleStatusChange = (e) => setStatusFilter(e.target.value);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
    };

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const handleAssignTill = (till) => {
        setSelectedTill(till); // Pass the entire till object
        setShowAssignTillModal(true);
    };

    const handleEditTill = (till) => {
        setSelectedTill(till); // Set till data for editing
        setShowAddTillModal(true); // Show the modal
    };

    const handleAddTill = () => {
        setSelectedTill(null); // Clear selected till for adding new till
        setShowAddTillModal(true); // Show the modal
    };

    return (
        <>
            <Header />
            <Sidebar />
            <Main page={page}>
                {selectedTill && (
                    <AssignTillToDispenser
                        show={showAssignTillModal}
                        handleClose={handleClose}
                        tillNumber={selectedTill.tillNumber} // Access tillNumber from the selected till
                    />
                )}

                <AddTillModal
                    show={showAddTillModal}
                    onHide={handleClose}
                    till={selectedTill}
                />

                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">List Till Numbers</h5>

                        <button
                            onClick={handleAddTill}
                            className="btn btn-primary"
                            disabled={!userCan(user, "POST:Payments:AddTill")}
                        >
                            Add Till
                        </button>

                        <div className="d-flex justify-content-between mb-3">
                            <input
                                type="text"
                                placeholder="Search by till name"
                                value={search}
                                onChange={handleSearchChange}
                                className="form-control me-2"
                            />
                            <select
                                value={statusFilter}
                                onChange={handleStatusChange}
                                className="form-control"
                            >
                                <option value="all">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Till Name</th>
                                    <th>Till Number</th>
                                    <th>Store Number</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userCan(user, "GET:Payments:GetAllTills") ? (
                                    filteredList.map((till) => (
                                        <tr key={till.id}>
                                            <td>{till.tillName}</td>
                                            <td>{till.tillNumber}</td>
                                            <td>{till.storeNumber}</td>
                                            <td>
                                                <span
                                                    className={
                                                        till.isActive
                                                            ? "badge bg-success"
                                                            : "badge bg-danger"
                                                    }
                                                >
                                                    {till.isActive
                                                        ? "Active"
                                                        : "Inactive"}
                                                </span>
                                            </td>
                                            <td>
                                                <DropdownButton
                                                    id={`dropdown-button-${till.id}`}
                                                    title="Actions"
                                                    variant=""
                                                    size="sm"
                                                >
                                                    {/* Update button, disabled if the user lacks permission */}
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleEditTill(till)
                                                        }
                                                        disabled={
                                                            !userCan(
                                                                user,
                                                                "POST:Payments:UpdateTill",
                                                            )
                                                        }
                                                    >
                                                        Update
                                                    </Dropdown.Item>

                                                    {/* Activate button, disabled if till is active or user lacks permission */}
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            changeTillStatus(
                                                                till.id,
                                                                true,
                                                            )
                                                        }
                                                        disabled={
                                                            till.isActive ||
                                                            !userCan(
                                                                user,
                                                                "POST:Payments:UpdateTill",
                                                            )
                                                        }
                                                    >
                                                        Activate
                                                    </Dropdown.Item>

                                                    {/* Assign Till to Dispenser button, disabled if the user lacks permission */}
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleAssignTill(
                                                                till,
                                                            )
                                                        }
                                                        disabled={
                                                            !userCan(
                                                                user,
                                                                "POST:Payments:AssignTillToDispenser",
                                                            )
                                                        }
                                                    >
                                                        Assign Till to Dispenser
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="alert alert-danger">
                                                You do not have permission to
                                                view this page
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Main>
        </>
    );
};

export default ListTillNumbers;
