import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import StationsContext from "../../context/otogas/stations/stationsContext";

const AddTank = ({ show, handleClose, stationCode, selectedTank }) => {
    const stationsContext = useContext(StationsContext);
    const { addStationTank, updateStationTank } = stationsContext;

    const [tank, setTank] = useState({
        tankName: "",
        stationCode: stationCode || "",
        tankCode: selectedTank ? selectedTank.tankCode : ""
    });

    useEffect(() => {
        if (selectedTank) {
            setTank({
                tankName: selectedTank.tankName,
                stationCode: selectedTank.stationCode,
                tankCode: selectedTank.tankCode
            });
        } else {
            setTank({
                tankName: "",
                stationCode: stationCode || "",
                tankCode: ""
            });
        }
    }, [selectedTank, stationCode]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setTank({
            ...tank,
            [name]: value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (tank.tankCode) {
            // Update Tank if tankCode exists
            updateStationTank(tank);
        } else {
            // Create a new tank if tankCode is not present
            addStationTank(tank);
        }
        console.log(tank);
        handleClose(); // Close the modal after submission
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{tank.tankCode ? "Update Tank" : "Add Tank"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="formTankName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Tank Name"
                            name="tankName"
                            value={tank.tankName}
                            onChange={onChange}
                            required
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={onSubmit}>
                    {tank.tankCode ? "Update Tank" : "Add Tank"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTank;
