import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import UserContext from "../../../context/users/userContext";
import AuthContext from "../../../context/auth/authContext";
import SweetAlertWrapper from "../sweetAlert";

const AssignUserApp = ({ show, handleClose, userId }) => {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const { assignUserApp, notification, clear_notifications } = userContext;
    const { list_apps, apps } = authContext;

    const [selectedApp, setSelectedApp] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        list_apps();
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const handleAssignApp = async () => {
        if (!selectedApp) {
            alert("Please select an app to assign");
            return;
        }

        const isConfirmed = window.confirm(
            "Are you sure you want to assign this app?",
        );
        if (isConfirmed) {
            assignUserApp(userId, selectedApp);
        }
    };

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notifications();
        handleClose();
    };

    const handleSelectApp = (selectedOption) => {
        setSelectedApp(selectedOption.value);
    };

    const appOptions = apps.map((app) => ({
        value: app.appsCode,
        label: app.appsName,
    }));

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Assign App</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SweetAlertWrapper
                    show={showAlert}
                    type={alertMessage.type || "info"}
                    title="Notification"
                    message={alertMessage.text}
                    confirmBtnText="OK"
                    onConfirm={handleConfirm}
                />
                <Form>
                    <Form.Group controlId="formSelectApp">
                        <Form.Label>Select App</Form.Label>
                        <Select
                            options={appOptions}
                            name="app"
                            onChange={handleSelectApp}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={appOptions.find(
                                (option) => option.value === selectedApp,
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAssignApp}>
                    Assign App
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignUserApp;
