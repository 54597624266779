import axios from "./index";

// lists
export const list_customers = async (
  page,
  page_size,
  customerName,
  customerPhone,
) => {
  // otogas/Customer/GetAllCustomers/1/100?customerName=100&customerPhone=099
  try {
    const res = await axios.get(
      `/otogas/Customer/GetAllCustomers/${page}/${page_size}?customerName=${customerName}&customerPhone=${customerPhone}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_products = async () => {
  try {
    const res = await axios.get("/otogas/Setup/GetProducts");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_stations = async () => {
  try {
    const res = await axios.get("/otogas/Station/GetAllStations");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// list vehicles
export const list_vehicles = async (
  page_size,
  page,
  customerName,
  vehicleRegistrationNumber,
  productCode,
  status,
) => {
  console.log(
    page,
    page_size,
    customerName,
    vehicleRegistrationNumber,
    productCode,
    status,
  );
  try {
    // otogas/Customer/GetAllVehicles/10/1?customerName=name&vehicleRegistrationNumber=name&productCode=1&status=true'
    const res = await axios.get(
      `/otogas/Customer/GetAllVehicles/${page}/${page_size}?customerName=${customerName}&vehicleRegistrationNumber=${vehicleRegistrationNumber}&productCode=${productCode}&status=${status}`,
    );
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// tills
export const list_tills = async () => {
  try {
    const res = await axios.get("/payments/Payments/GetAllTills");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const add_till = async (formData) => {
  try {
    const res = await axios.post("/payments/Payments/AddTill", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_till = async (formData) => {
  try {
    const res = await axios.post("/payments/Payments/UpdateTill", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const assign_till_to_dispenser = async (formData) => {
  try {
    const res = await axios.post(
      "/payments/Payments/AssignTillToDispenser",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const change_till_status = async (tillId, status) => {
  try {
    const res = await axios.post(
      `/otogas/UpdateTillStatus?tillId=${tillId}&IsActive=${status}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// customer

export const create_customer = async (formData) => {
  try {
    const res = await axios.post("/otogas/Customer/AddCustomer", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_customer_status = async (customer_id, status) => {
  try {
    const res = await axios.post(
      `/otogas/UpdateCustomerStatus?customerId=${customer_id}&IsActive=${status}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_customer_info = async (customerCode, formData) => {
  try {
    const res = await axios.post(
      `/otogas/Customer/UpdateCustomer?customerCode=${customerCode}`,
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const search_customer = async (search_customer) => {
  // https://os.protoenergy.com/otogas/Customer/SearchVehicle?VehicleRegNo=KBR%20450L&StationCode=0001' \
  try {
    const res = await axios.post(
      `/otogas/Customer/SearchVehicle?VehicleRegNo=${search_customer.regno}&StationCode=${search_customer.station}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// vehicle
export const add_vehicle = async (formData) => {
  try {
    const res = await axios.post("/otogas/Customer/AddVehicle", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_vehicle = async (formData) => {
  try {
    const res = await axios.patch(
      "/otogas/Customer/UpdateVehicle",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_vehicle_status = async (vehicleCode, status) => {
  //    otogas/Customer/DeactivateVehicle?vehicleCode=00'
  // otogas/Customer/ActivateVehicle?vehicleCode=2340'

  if (status === "activate") {
    try {
      const res = await axios.post(
        `/otogas/Customer/ActivateVehicle?vehicleCode=${vehicleCode}`,
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  } else {
    try {
      const res = await axios.post(
        `/otogas/Customer/DeactivateVehicle?vehicleCode=${vehicleCode}`,
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
};

export const uninstall_vehicle = async (vehicleCode) => {
  console.log(vehicleCode, "vehicleCode");
  try {
    const res = await axios.post(
      `/otogas/Customer/MarkVehicleAsUnInstalled?vehicleCode=${vehicleCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
export const search_vehicle_by_reg_no = async (reg_no) => {
  try {
    const res = await axios.post(
      `/otogas/SearchVehicleByRegNumber?vehicleRegistrationNumner=${reg_no}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const transfer_vehicle = async (formData) => {
  console.log(formData, "formdata");
  try {
    const res = await axios.post(
      "/otogas/Customer/TransferVehicle",
      formData,
    );
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// orders

export const create_order = async (formData) => {
  try {
    const res = await axios.post("/otogas/AddOrder", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const add_plan = async (formData) => {
  try {
    const res = await axios.post("/otogas/AddPlan", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const cancel_delivery_plan = async (deliveryPlanId) => {
  try {
    const res = await axios.post(`/otogas/CancelDeliveryPlan`, {
      deliveryPlanId,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const plan_assignment = async (formData) => {
  try {
    const res = await axios.put("/otogas/PlanAssignments", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const attach_order = async (deliveryPlanId, orderIds) => {
  try {
    const res = await axios.post("/otogas/AttachOrder", {
      deliveryPlanId,
      orderIds,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_orders = async () => {
  try {
    const res = await axios.get("/bulk/Bulk/BulkOrders");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// loading
export const new_loading = async (formData) => {
  try {
    const res = await axios.post("/otogas/Loading", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const offloading = async (formData) => {
  try {
    const res = await axios.post("/otogas/Offloading", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deliverorder = async (formData) => {
  try {
    const res = await axios.post("/otogas/DeliverOrder", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const remove_order = async (orderId) => {
  try {
    // stringify the order id

    const res = await axios.delete("/otogas/RemoveOrder", [
      JSON.stringify(orderId),
    ]);

    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// approvals
export const finance_approval = async (formData) => {
  try {
    const res = await axios.post("/otogas/FinanceApproval", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// manager approval
export const manager_approval = async (formData) => {
  try {
    const res = await axios.post("/otogas/ManagerApproval", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// stations

export const add_stations = async (formData) => {
  try {
    const res = await axios.post("/otogas/Station/AddStation", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_station = async (formData) => {
  try {
    const res = await axios.post("/otogas/UpdateStation", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_station_status = async (stationCode, status) => {
  try {
    const res = await axios.post(
      `/otogas/UpdateStationStatus?stationCode=${stationCode}&isActive=${status}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const add_station_tank = async (formData) => {
  try {
    const res = await axios.post("/otogas/Station/AddTank", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_station_tanks = async (stationCode) => {
  try {
    const res = await axios.get(
      `/otogas/Station/StationTank?stationCode=${stationCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_station_tank = async (formData) => {
  try {
    const res = await axios.post("/otogas/UpdateStationTank", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_tanks = async () => {
  try {
    const res = await axios.get("/otogas/Station/GetAllTanks");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// export const list_tanks = async (station) => {
//     try {
//         const res = await axios.get(`/otogas/StationTanks?stationCode=${station}`);
//         return res.data;
//     }
//     catch (error) {
//         return error.response.data;
//     }
// }

export const search_station = async (stationCode) => {
  try {
    const res = await axios.get(
      `/otogas/SearchStation?stationCode=${stationCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// station nozzles
export const add_nozzle = async (formData) => {
  try {
    const res = await axios.post("/otogas/Station/AddNozzle", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_nozzle = async (formData) => {
  try {
    const res = await axios.post("/otogas/UpdateStationNozzle", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_nozzles = async (station) => {
  try {
    const res = await axios.get(`/otogas/Station/GetAllNozzles`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// station dispensers
export const add_station_dispenser = async (formData) => {
  try {
    const res = await axios.post("/otogas/Station/AddDispenser", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const update_station_dispenser = async (formData) => {
  try {
    const res = await axios.post(
      "/otogas/UpdateStationDispenser",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_dispensers = async () => {
  try {
    const res = await axios.get(`/otogas/Station/GetAllDispensers`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_station_dispensers = async (stationCode) => {
  try {
    const res = await axios.get(
      `/otogas/Station/ListStationDispensers?stationCode=${stationCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// list dispenser nozzles
export const list_dispenser_nozzles = async (dispenserCode) => {
  try {
    const res = await axios.get(
      `/otogas/Station/ListDispenserNozzles?dispenserCode=${dispenserCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const assign_user_to_dispenser = async (formData) => {
  console.log(formData, "formdata");
  try {
    const res = await axios.post(
      "/otogas/Station/AssignDispenser",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

//  sales
export const add_sale = async (formData) => {
  try {
    const res = await axios.post("/otogas/Sales/AddMisingSale", formData);
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const transfer_sale_to_another_nozzle = async (
  transactionCode,
  nozzleCode,
) => {
  // otogas/Sales/TransferSaleToAnotherNozzle?transactionCode=009&nozzleCode=3445'
  try {
    const res = await axios.post(
      `/otogas/Sales/TransferSaleToAnotherNozzle?transactionCode=${transactionCode}&nozzleCode=${nozzleCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const reverse_a_sale = async (transactionCode) => {
  try {
    const res = await axios.post(
      `/otogas/Sales/ReverseASale?transactionCode=${transactionCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const remove_sale = async (saleId) => {
  try {
    const res = await axios.post(
      `/otogas/Sales/ReverseasaleAsync?saleid=${saleId}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const clear_variance = async (type, shiftNumber) => {
  if (type === "defer") {
    try {
      const res = await axios.post(
        `/otogas/Sales/DeferVariance?shiftNumber=${shiftNumber}`,
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  } else if (type === "write_off") {
    try {
      const res = await axios.post(
        `/otogas/Sales/WriteOffVariance?shiftNumber=${shiftNumber}`,
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
};

export const stoke_take = async (formData) => {
  try {
    const res = await axios.post("/otogas/Stock/StokeTake", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// variance

// otogas/Stock/Shiftvariances
export const shift_variance = async () => {
  try {
    const res = await axios.get("/otogas/Stock/AllVariances");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// list sales
export const list_sales = async (
  shiftNumber,
  stationCode,
  dispenserName,
  nozzleName,
  paymentTypeName,
  startDate,
  endDate,
  pageNumber,
  pageSize,
  orderByColumn,
  isDescending,
) => {
  try {
    // otogas/Sales/AllSales?shiftNumber=12&dispenserName=23&nozzleName=23&paymentTypeName=23&startDate=32&endDate=32&pageNumber=1&pageSize=10' \
    console.log("request", await axios.get(
      `/otogas/Sales/AllSales?stationCode=${stationCode}&shiftNumber=${shiftNumber}&dispenserName=${dispenserName}&nozzleName=${nozzleName}&paymentTypeName=${paymentTypeName}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderByColumn=${orderByColumn}&isDescending=${isDescending}`
    ))

    const res = await axios.get(
      `/otogas/Sales/AllSales?stationCode=${stationCode}&shiftNumber=${shiftNumber}&dispenserName=${dispenserName}&nozzleName=${nozzleName}&paymentTypeName=${paymentTypeName}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderByColumn=${orderByColumn}&isDescending=${isDescending}`,
    );
    console.log(res, "this is the response");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_payment_types = async () => {
  try {
    const res = await axios.get("/otogas/Sales/AllPaymentTypes");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_app_payment_types = async () => {
  try {
    const res = await axios.get("/otogas/Sales/MobileAppPaymentTypes");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_topup_types = async () => {
  try {
    const res = await axios.get("/otogas/Sales/TopUpTypes");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}

export const add_payment_type = async (formData) => {
  try {
    const res = await axios.post("/otogas/Setup/AddPaymentType", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_customer_wallets = async () => {
  try {
    const res = await axios.get("/otogas/Sales/GetAllCustomerBalances");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const download_cutomer_wallet_topup_history = async (from, to) => {
  // otogas/Sales/WalletTopUps?dateFrom=1&dateTo=1'
  console.log(to, from, "to, from");

  const url = `otogas/Sales/WalletTopUps?dateFrom=${from}&dateTo=${to}`
  return downloadFile(url, "WalletTopUps.xlsx")

}

export const top_up_customer_balance = async (formData) => {
  console.log(formData, "formdata ++++");
  try {
    const res = await axios.post(
      "/otogas/Sales/TopUpCustomerWallet",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const set_global_pricing = async (productCode, newPrice) => {
  // otogas/Setup/GlobalPriceChange?productCode=02&newPrice=1000'
  try {
    const res = await axios.get(
      `/otogas/Setup/GlobalPriceChange?productCode=${productCode}&newPrice=${newPrice}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const change_station_price = async (formData) => {
  try {
    const res = await axios.post("/otogas/Setup/ChangePrice", formData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const add_product = async (product) => {
  try {
    const res = await axios.post("/otogas/Setup/AddProduct", product);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const get_vehicle_summary = async (vehicleCode, startDate, endDate) => {
  // otogas/Sales/GetCustomerStatement/12621/2024-08-29/2024-09-29
  // otogas/Sales/GetCustomerStatement/12621/2024-08-28/2024-09-28
  console.log(
    vehicleCode,
    startDate,
    endDate,
    "vehicleCode, startDate, endDate",
  );
  console.log(
    `/otogas/Sales/GetCustomerStatement/${vehicleCode}/${startDate}/${endDate}`,
  );
  try {
    const res = await axios.post(
      `/otogas/Sales/GetCustomerStatement/${vehicleCode}/${startDate}/${endDate}`,
    );
    console.log(res, "res++++");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const get_dashboard_data = async () => {
  try {
    const res = await axios.get("/otogas/Sales/GetDashBoardData");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const set_initial_stock = async (formData) => {
  try {
    console.log(formData, "formData");
    const res = await axios.post("/otogas/Stock/Initialstocktake", {
      readings: [formData],
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const searchVehicle = async (stationCode, vehicleRegNo) => {
  try {
    const res = await axios.get(
      `/otogas/Customer/SearchVehicle/${stationCode}/${vehicleRegNo}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const searchEmployee = async (userCode) => {
  console.log(userCode, "usercode");
  try {
    const response = await axios.get(
      `/otogas/Sales/GetEmployeePrice?userCode=${userCode}`,
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error("Error during employee search:", error);
    return (
      error.response?.data || {
        error: "An error occurred while searching the employee",
      }
    );
  }
};

export const validatePaymentReference = async (dispenserCode, transId) => {
  // payments/Payments/ConfirmPayment/SIF2O6HUMS/D001
  try {
    const response = await axios.post(
      `/payments/Payments/ConfirmPayment/${transId}/${dispenserCode}`,
    );
    console.log(response, "response 09000");
    return response.data;
  } catch (error) {
    console.error("Error during payment validation:", error);
    return (
      error.response?.data || {
        error: "An error occurred while validating the payment",
      }
    );
  }
};

export const list_station_assignments = async (stationCode) => {
  console.log(stationCode, "stationCode");
  try {
    const res = await axios.get(
      `/otogas/Station/GetAllDispenserAssignments/${stationCode}`,
    );
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const list_tank_sizes = async () => {
  try {
    const res = await axios.get("/otogas/Customer/GetTankSizes");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const register_pda = async (formData) => {
  console.log(formData, "formData");

  try {
    const res = await axios.post(
      `/otogas/Setup/RegisterPDA?deviceName=${formData.deviceName}&deviceIMEI=${formData.deviceIMEI}&deviceSerialNumber=${formData.deviceSerialNumber}&deviceModel=${formData.deviceModel}&dispensercode=${formData.dispenserCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const add_walk_in_vehicle = async (formData) => {
  try {
    const res = await axios.post(
      "/otogas/Customer/RegisterNonOtogasVehicle",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const get_mpesa_payments = async (
  tillNumber,
  startDate,
  endDate,
  transId,
) => {
  // payments/Payments/MpesaTransactions?tillNumber=6055280&dateFrom=1&dateTo=2&transId=3
  console.log(tillNumber, startDate, endDate, transId, "tillNumber, startDate, endDate, transId");
  try {
    const res = await axios.get(
      `/payments/Payments/MpesaTransactions?tillNumber=${tillNumber}&dateFrom=${startDate}&dateTo=${endDate}&transId=${transId}`,
    );
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const activate_mpesa = async (transId) => {
  try {
    const res = await axios.put(
      `/payments/Payments/ActivateMpesa?transId=${transId}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deactivate_mpesa = async (transId) => {
  try {
    const res = await axios.put(
      `/payments/Payments/BlockMpesa?transId=${transId}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// /payments/Payments/AddMpesaTransaction, payload
export const add_mpesa_transaction = async (formData) => {
  try {
    const res = await axios.post(
      "/payments/Payments/AddMpesaTransaction",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const download_vehicle_statement = async (vehicleCode) => {
  // https://localhost:44369/otogas/Sales/export-customer-transactions/12617

  const url = `/otogas/Sales/export-customer-transactions/${vehicleCode}`
  return downloadFile(url, "vehicle_statement.xlsx");

}

export const download_vehicle_statement_pdf = async (vehicleCode) => {

  const url = `/otogas/Sales/export-customer-transactions-pdf/${vehicleCode}`
  return downloadFile(url, "vehicle_statement.pdf", "application/pdf");


};

export const download_customer_balances = async () => {

  const url = "/otogas/Sales/export-customer-wallet-balances"
  return downloadFile(url, "customer_balances.xlsx");
};

export const download_customer_statement = async (customerCode, type) => {
  const url = type === "pdf"
    ? `/otogas/Sales/export-customer-transactions-pdf/${customerCode}`
    : `/otogas/Sales/CustomerAllVehiclesStatement/${customerCode}`;

  const file_extension = type === "pdf" ? "pdf" : "xlsx";

  downloadFile(url, `customer_statement.${file_extension}`, `application/${file_extension}`);

};

export const upload_sales_data = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const res = await axios.post(
      "/otogas/Sales/UploadSalesData",
      formData,
      {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const view_sale_payments = async (saleId) => {
  // otogas/Sales/ViewPayments/QO4LDJAMJRO93
  console.log(saleId, "saleId");
  try {
    const res = await axios.get(`/otogas/Sales/ViewPayments/${saleId}`);
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const get_vehicle_fueling_events = async (vehicleCode) => {
  try {
    const res = await axios.get(
      `/otogas/Sales/GetFuelingEventsForVehicle/${vehicleCode}`,
    );
    console.log(res, "res");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const get_vehicle_by_customer_code = async (vehicleCode) => {
  try {
    const res = await axios.get(
      `/otogas/Customer/GetVehicleByCustomerCode?customerCode=${vehicleCode}`,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

const downloadFile = async (url, fileName = "download", fileType = "application/octet-stream") => {
  try {
    const res = await axios.get(url, {
      responseType: "blob",
      validateStatus: function (status) {
        return status < 500; // Resolve for statuses below 500
      },
    });

    console.log(res, "res");

    // Handle status codes
    if (res.status === 200) {
      const blob = new Blob([res.data], { type: fileType });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } else if (res.status === 404) {
      console.log("File not found");
      return "File not found";
    }

    else if (res.status === 403) {

      return "You are not authorized to download this file";
    }

    else {

      return `Error: ${res.statusText}`;
    }
  } catch (error) {

    return error.response?.data || error.message;
  }
};

export const download_sales_per_day = (date) => {
  const url = `/otogas/Sales/ExportDailySales?date=${date}`;
  return downloadFile(url, `daily_sales_${date}.xlsx`);
};


export const download_monthly_sales = async (month, year) => {
  // /otogas/Sales/ExportMonthlySales2/3'
  console.log(month, year, "month, year");
  const url = `/otogas/Sales/ExportMonthlySales/${month}/${year}`

  return downloadFile(url, `monthly_sales_${month}_${year}.xlsx`);

};

export const set_credit_limit = async (formData) => {
  try {
    const res = await axios.post(
      "/otogas/Customer/UpdateCreditLimit",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const set_customer_credit_limit = async (formData) => {
  console.log(formData, "formData");
  try {
    const res = await axios.post(
      "/otogas/Customer/CustomerCreditLimit",
      formData,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}

export const mpesa_code_usage = async (mpesaCode) => {
  try {
    const res = await axios.get(
      `/payments/Payments/GetMpesaCodeUsage?transId=${mpesaCode.trim()}`
    );
    console.log(res, "kskkdkfekk");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
  // 
}

export const export_all_customers = async () => {
  const url = "/otogas/Customer/export-all-customers";
  return downloadFile(url, "all_customers.xlsx");
}

export const get_totalizer_readings = async (date) => {
  // try {otogas/Stock/GetTotalizerReadings/2024-10-20'
  try {
    const res = await axios.get(`/otogas/Stock/GetTotalizerReadings/${date}`);
    return res.data;
  }
  catch (error) {
    return error.response.data;
  }
}

export const adjust_stock_take = async (formData) => {
  try {
    const res = await axios.post("/otogas/Stock/AdjustStockTake", formData);
    return res.data;
  }
  catch (error) {
    return error.response.data;
  }
}

export const transfer_vehicle_balance = async (formData) => {
  try {
    const res = await axios.post("/otogas/Sales/TransferWalletAmount", formData);
    return res.data;
  }
  catch (error) {
    return error.response.data;
  }
}

export const exportVariancesReport = async () => {
  try {
    const url = "/otogas/Stock/ExportVarianceReport"
    downloadFile(url, "variance_report.xlsx");
  }
  catch (error) {
    return error.response.data;
  }
}

export const messageBalance = async (sender) => {
  // otogas/Messaging/GetCreditBalance?sender=Otogas
  try {
    const res = await axios.get(`/otogas/Messaging/GetCreditBalance?sender=${sender}`);
    return res.data;
  }
  catch (error) {
    return error.response.data;
  }
}
