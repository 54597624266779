import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import Main from '../../components/Layout/Main'

import Spinner from '../../components/Layout/spinner'

const ListDeliveryPoints = () => {
    const [delivery_points, setDelivery_points] = useState([])
    const [loading, setLoading] = useState(false)

    const getDelivery_points = async () => {

        try {
            const res = await fetch("https://pythonitruck.azurewebsites.net/delivery_points")
            const data = await res.json()
            setDelivery_points(data.delivery_points)
            setLoading(false)
        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        getDelivery_points()
    }, [])

    const page = 'List Delivery Points'

    const [search, setSearch] = useState('')
    const onSearch = (e) => {

        setSearch(e.target.value)
        console.log(search)
    }

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                {delivery_points.length > 0 && !loading ? (

                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Delivery_points Table</h5>
                            <input type="text" className="form-control" id="search" onChange={onSearch} name="search" placeholder="Search" />

                            <table className="table table-responsive ">
                                <thead>

                                    <tr >
                                        <th scope="col">#</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Latitude</th>
                                        <th scope="col">Longitude</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        delivery_points

                                            .filter((delivery_point) => {
                                                return search.toLowerCase() === '' ? delivery_points :
                                                    delivery_point.Code.toLowerCase().includes(search.toLowerCase()) || delivery_point.Name.toLowerCase().includes(search.toLowerCase())
                                            })

                                            .map((delivery_point) => (
                                                <tr key={delivery_point.Id}>

                                                    <td key={delivery_point.Id}> {delivery_points.indexOf(delivery_point) + 1} </td>
                                                    <td> {delivery_point.Code} </td>
                                                    <td> {delivery_point.Name} </td>
                                                    <td> {delivery_point.Latitude} </td>
                                                    <td> {delivery_point.Longitude} </td>

                                                    <td>

                                                    </td>
                                                </tr>
                                            )
                                            )
                                    }

                                </tbody>

                            </table>
                        </div>
                    </div>) : (
                    <Spinner />
                )}
            </Main>
        </div>

    )
}

export default ListDeliveryPoints