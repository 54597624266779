import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Form } from "react-bootstrap";
import StationsContext from "../../context/otogas/stations/stationsContext";

const AddReportRecipientForm = ({ show, handleClose, reportCode }) => {
    // Initialize the form with react-hook-form
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const stationsContext = useContext(StationsContext);
    const { addReportRecipient } = stationsContext;

    // Handle form submission
    const onSubmit = async (data) => {
        const { recipient, type } = data;
        addReportRecipient(reportCode, recipient, type);

    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Report Recipient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {/* Recipient Input */}
                    <Form.Group controlId="recipient">
                        <Form.Label>Recipient</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Recipient Email"
                            {...register("recipient", { required: "Recipient is required" })}
                        />
                        {errors.recipient && <span className="text-danger">{errors.recipient.message}</span>}
                    </Form.Group>

                    {/* Type Select */}
                    <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...register("type", { required: "Type is required" })}
                        >
                            <option value="">Select Type</option>
                            <option value="0">To</option>
                            <option value="1">CC</option>
                        </Form.Control>
                        {errors.type && <span className="text-danger">{errors.type.message}</span>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                    Add Recipient
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddReportRecipientForm;
