import React, { useState, useEffect, useContext } from "react";
import {
    Card,
    Button,
    Modal,
    Form,
    Breadcrumb,
    Container,
    Row,
    Col,
    Table,
} from "react-bootstrap";
import Select from "react-select";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import AddProductModal from "./addProduct";
import AddPaymentTypeModal from "./addPaymentType";
import GlobalPriceChangeModal from "./bulkUpdatePrice";
import StationsContext from "../../context/otogas/stations/stationsContext";
import CustomersContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import userCan from "../../config/user_can";
import AuthContext from "../../context/auth/authContext";

function DispenserPricingSettings() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [showAddPaymentTypeModal, setShowAddPaymentTypeModal] =
        useState(false);
    const [showBulkUpdatePriceModal, setShowBulkUpdatePriceModal] =
        useState(false);
    const [selectedStation, setSelectedStation] = useState(null); // State for selected station
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [newPrice, setNewPrice] = useState("");
    const [productFilter, setProductFilter] = useState(null); // Filter for products

    const customersContext = useContext(CustomersContext);
    const stationsContext = useContext(StationsContext);
    const authContext = useContext(AuthContext);
    const { listProducts, notification, clear_notification } = customersContext;
    const {
        stations,
        listStations,
        setStationPrice,
        notification: customerNotification,
        clear_notification: clear_customer_notification,
    } = stationsContext;
    const [hasSetDefault, setHasSetDefault] = useState(false);
    const { user } = authContext;

    useEffect(() => {
        listStations();
        listProducts();
    }, []);

    useEffect(() => {
        if (stations.length > 0 && !hasSetDefault) {
            setSelectedStation(stations[0]);
            setHasSetDefault(true); // Ensure the default station is only set once
        }
    }, [stations, hasSetDefault]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    useEffect(() => {
        if (customerNotification) {
            setAlertMessage({
                text: customerNotification.text,
                type: customerNotification.type,
            });
            setShowAlert(true);
        }
    }, [customerNotification]);

    const handleConfirm = async () => {
        const previousStationCode = selectedStation?.stationCode; // Save the currently selected station's code

        listStations();
        listProducts();

        const refreshedStation = stations.find(
            (station) => station.stationCode === previousStationCode,
        );
        if (refreshedStation) {
            setSelectedStation(refreshedStation);
        } else if (stations.length > 0) {
            setSelectedStation(stations[0]); // Fallback to the first station if previous one is not found
        }
        setShowAlert(false);
        setShowAddProductModal(false);
        setShowAddPaymentTypeModal(false);
        setShowBulkUpdatePriceModal(false);
        clear_notification();
    };

    const handleClose = () => {
        setShowAddProductModal(false);
        setShowAddPaymentTypeModal(false);
        setShowBulkUpdatePriceModal(false);
        clear_notification();
    };

    const handleEditClick = (station, product) => {
        setSelectedStation(station);
        setSelectedPrice(product);
        setNewPrice(product.amount);
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        await setStationPrice([
            {
                stationCode: selectedStation.stationCode,
                productCode: selectedPrice.productCode,
                newPrice: newPrice,
            },
        ]);

        // Update the price in the local state
        const updatedPrices = selectedStation.prices.map((price) =>
            price.productCode === selectedPrice.productCode
                ? { ...price, amount: newPrice }
                : price,
        );

        // Update the selected station with the new prices
        setSelectedStation((prev) => ({
            ...prev,
            prices: updatedPrices,
        }));

        setShowEditModal(false);
    };

    return (
        <div className="station-pricing-settings-page">
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                <Container fluid className="mt-5">
                    <Row className="mb-3">
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">
                                    Settings
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Station Prices
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <h2>Station Pricing Management</h2>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {/* Add New Product button, disabled if user doesn't have permission */}
                            <Button
                                variant="primary"
                                className="me-2"
                                onClick={() => setShowAddProductModal(true)}
                                disabled={
                                    !userCan(user, "POST:Setup:AddProduct")
                                }
                            >
                                Add New Product
                            </Button>

                            {/* Add New Payment Type button, disabled if user doesn't have permission */}
                            <Button
                                variant="secondary"
                                className="me-2"
                                onClick={() => setShowAddPaymentTypeModal(true)}
                                disabled={
                                    !userCan(user, "POST:Setup:AddPaymentType")
                                }
                            >
                                Add New Payment Type
                            </Button>

                            {/* Bulk Update Prices button, disabled if user doesn't have permission */}
                            <Button
                                variant="warning"
                                className="me-2"
                                onClick={() =>
                                    setShowBulkUpdatePriceModal(true)
                                }
                            >
                                Bulk Update Prices
                            </Button>
                        </Col>
                    </Row>

                    {/* Station Filter */}
                    <Row className="mb-4">
                        <Col>
                            <Select
                                options={stations.map((station) => ({
                                    value: station.stationCode,
                                    label: station.stationName,
                                }))}
                                placeholder="Select Station"
                                value={
                                    selectedStation
                                        ? {
                                              value: selectedStation.stationCode,
                                              label: selectedStation.stationName,
                                          }
                                        : null
                                }
                                onChange={(selectedOption) => {
                                    const selected = stations.find(
                                        (station) =>
                                            station.stationCode ===
                                            selectedOption.value,
                                    );
                                    setSelectedStation(selected);
                                }}
                            />
                        </Col>
                    </Row>

                    {/* Station Details Card */}
                    {selectedStation && (
                        <Row className="mb-4">
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <h5>
                                            {selectedStation.stationName} -
                                            Station Details
                                        </h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>
                                                <p>
                                                    <strong>
                                                        Station Code:
                                                    </strong>{" "}
                                                    {
                                                        selectedStation.stationCode
                                                    }
                                                </p>
                                                <p>
                                                    <strong>Status:</strong>{" "}
                                                    {selectedStation.status}
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <p>
                                                    <strong>Location:</strong>{" "}
                                                    {selectedStation.location}
                                                </p>
                                                <p>
                                                    <strong>Created At:</strong>{" "}
                                                    {new Date(
                                                        selectedStation.dateCreated,
                                                    ).toLocaleDateString()}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}

                    {/* Products Table */}
                    {selectedStation && (
                        <Row>
                            <Col md={12}>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Product Code</th>
                                            <th>Product Name</th>
                                            <th>Current Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedStation.prices
                                            .filter((price) =>
                                                productFilter
                                                    ? price.productCode ===
                                                      productFilter.value
                                                    : true,
                                            ) // Filter products
                                            .map((price) => (
                                                <tr key={price.productCode}>
                                                    <td>
                                                        {price.productCode ||
                                                            "Unknown"}
                                                    </td>
                                                    <td>
                                                        {price.productName ||
                                                            "Unknown"}
                                                    </td>
                                                    <td>{price.amount}</td>
                                                    <td>
                                                        <Button
                                                            variant="outline-primary"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleEditClick(
                                                                    selectedStation,
                                                                    price,
                                                                )
                                                            }
                                                        >
                                                            Edit Price
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}

                    {/* Modals */}
                    <AddProductModal
                        show={showAddProductModal}
                        handleClose={() => setShowAddProductModal(false)}
                    />
                    <AddPaymentTypeModal
                        show={showAddPaymentTypeModal}
                        handleClose={() => setShowAddPaymentTypeModal(false)}
                    />
                    <GlobalPriceChangeModal
                        show={showBulkUpdatePriceModal}
                        handleClose={() => setShowBulkUpdatePriceModal(false)}
                    />

                    {/* Edit Price Modal */}
                    <Modal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Price</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Station</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedStation?.stationName}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedPrice?.productCode}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>New Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={newPrice}
                                        onChange={(e) =>
                                            setNewPrice(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => setShowEditModal(false)}
                            >
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSaveChanges}
                            >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </Main>
        </div>
    );
}

export default DispenserPricingSettings;
