import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const DownloadButton = ({ existingFunction, reportType, buttonName }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const today = new Date().toISOString().split('T')[0];
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const handleDownload = async () => {
    setLoading(true);
    if (reportType === 'daily' && selectedDate) {
      await existingFunction(selectedDate);
    } else if (reportType === 'monthly' && selectedMonth && selectedYear) {
      await existingFunction(selectedMonth, selectedYear);
    } else if (reportType === 'range' && startDate && endDate) {
      await existingFunction(startDate, endDate);
    }
    setLoading(false);
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Default button text based on report type
  const defaultButtonText = reportType === 'daily'
    ? 'Download Daily Report'
    : reportType === 'monthly'
    ? 'Download Monthly Report'
    : 'Download Date Range Report';

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {buttonName || defaultButtonText}
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Select {reportType === 'daily' ? 'Date' : reportType === 'monthly' ? 'Month and Year' : 'Start and End Dates'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {reportType === 'daily' ? (
              <Form.Group>
                <Form.Label>Select a Date:</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  max={today}
                />
              </Form.Group>
            ) : reportType === 'monthly' ? (
              <>
                <Form.Group>
                  <Form.Label>Select Month:</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value="">Select Month</option>
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i} value={String(i + 1).padStart(2, '0')}>
                        {new Date(0, i).toLocaleString('default', { month: 'long' })}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Select Year:</Form.Label>
                  <Form.Control
                    type="number"
                    value={selectedYear}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value <= currentYear) {
                        setSelectedYear(value);
                      }
                    }}
                    min="2000"
                    max={currentYear}
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group>
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={today}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    max={today}
                    min={startDate}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleDownload}
            disabled={
              loading ||
              (reportType === 'daily' && !selectedDate) ||
              (reportType === 'monthly' && (!selectedMonth || !selectedYear)) ||
              (reportType === 'range' && (!startDate || !endDate || endDate < startDate))
            }
          >
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Downloading...
              </>
            ) : (
              'Download'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadButton;
