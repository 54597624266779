import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import Main from '../../components/Layout/Main'
import { Link } from 'react-router-dom'
import OrderContext from '../../context/orders/ordersContext'
import Spinner from '../../components/Layout/spinner'



const OtogasSales = () => {
    const orderContext = useContext(OrderContext);
    const { orders, getOrders, loading } = orderContext;


    useEffect(() => {
        getOrders()
    }, [])

    const page = 'Edit Customer'

    const [search, setSearch] = useState('')
    const onSearch = (e) => {

        setSearch(e.target.value)
    }

    return (


        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Sales</h5>
                        <input type="text" className="form-control" id="search" onChange={onSearch} name="search" placeholder="Search" />

                        {/* 
                CustomerName	TillNumber	TerminalName	ShiftNumber	RegistrationNumber	Product	PaymentType	Quantity	UnitPrice	RunningReading	AmountPaid	DispenserName	NozzleName	StorageLocation

                
                */}
                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">OrderId</th>
                                    <th scope="col">Shift Number</th>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col">Outlet</th>
                                    <th scope="col">Attendant</th>
                                    <th scope="col">Vehicle Registration</th>
                                    <th scope="col">Till Number</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Dispenser</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                    <td>
                                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                            ...
                                        </a>

                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                            <li className="dropdown-header">
                                                <h6>Kevin Anderson</h6>
                                                <span>Web Designer</span>
                                            </li>
                                            <li>
                                            </li>

                                            <li>
                                                {/* password reset modal */}
                                                <a className="dropdown-item d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target="#basicModal">
                                                    <i className="bi bi-person"></i>
                                                    <span>My Profile</span>
                                                </a>
                                            </li>
                                            <li>
                                            </li>

                                            <li>
                                                <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                                    <i className="bi bi-gear"></i>
                                                    <span>Account Settings</span>
                                                </a>
                                            </li>
                                            <li>
                                            </li>

                                            <li>
                                                <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                                    <i className="bi bi-question-circle"></i>
                                                    <span>Need Help?</span>
                                                </a>
                                            </li>
                                            <li>
                                            </li>

                                            <li>
                                                <a className="dropdown-item d-flex align-items-center" href="#">
                                                    <i className="bi bi-box-arrow-right"></i>
                                                    <span>Sign Out</span>
                                                </a>
                                            </li>

                                        </ul>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>

                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>1</td>
                                    <td>5678909876456</td>
                                    <td>SHJUR1</td>
                                    <td>Proto Karen </td>
                                    <td>John Doe</td>
                                    <td>KBN 345D</td>
                                    <td>677488</td>
                                    <td>Outright</td>
                                    <td>45</td>
                                    <td>120</td>
                                    <td>Dispenser 1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Main>
        </div>


    )
}

export default OtogasSales

