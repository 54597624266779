import React, { useContext, useEffect } from "react";
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import Footer from '../../components/Layout/Footer';
import { useParams } from "react-router-dom";

import CustomersContext from "../../context/otogas/customers/customersContext";


const ListSales = () => {
    const { saleId } = useParams(); // Get the saleId from URL params
    const customersContext = useContext(CustomersContext); // Use CustomersContext
    const { sale_payments, viewSalePayments } = customersContext; // Destructure required state and functions

    // UseEffect to call viewSalePayments whenever saleId changes
    useEffect(() => {
        if (saleId) {
            viewSalePayments(saleId); // Fetch sale payments for the given saleId
        }
    }, [saleId]); // Add viewSalePayments to the dependency array to avoid stale closure issues

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Sale" />
            <Main>
                <h2>Sale Breakdown</h2>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Sale ID</th>
                            <th>Amount Credit</th>
                            <th>Amount Debit</th>
                            <th>Payment Reference</th>
                            <th>Date Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sale_payments && sale_payments.length > 0 ? (
                            sale_payments.map((sale, index) => (
                                <tr key={index}>
                                    <td>{sale.saleId}</td>
                                    <td>{sale.amountCredit}</td>
                                    <td>{sale.amountDebit}</td>
                                    <td>{sale.paymentRefrence}</td>
                                    <td>{new Date(sale.dateCreated).toLocaleString()}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No sales available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Main>
            <Footer />
        </div>
    );
};

export default ListSales;
